
import {Options, Vue} from 'vue-class-component';
import ImagePreview from "@/layout/popup/info/image-preview.vue";
import {mapGetters} from "vuex";

@Options({
  name: 'print-theme',
  components: {ImagePreview},
  props: ['info'],
  computed: {
    ...mapGetters(['getCurrentStep'])
  }
})
export default class PrintTheme extends Vue {
}
