import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_business_card = _resolveComponent("business-card")!
  const _component_flyer_card = _resolveComponent("flyer-card")!
  const _component_letterhead_card = _resolveComponent("letterhead-card")!
  const _component_giftcard_card = _resolveComponent("giftcard-card")!
  const _component_birthdaycard_card = _resolveComponent("birthdaycard-card")!
  const _component_pencilselling_card = _resolveComponent("pencilselling-card")!
  const _component_contract_card = _resolveComponent("contract-card")!
  const _component_achievement_card = _resolveComponent("achievement-card")!
  const _component_pavement_card = _resolveComponent("pavement-card")!
  const _component_rollup_card = _resolveComponent("rollup-card")!
  const _component_companysign_card = _resolveComponent("companysign-card")!
  const _component_coffeecup_card = _resolveComponent("coffeecup-card")!
  const _component_beachflag_card = _resolveComponent("beachflag-card")!
  const _component_pen_card = _resolveComponent("pen-card")!
  const _component_bags_card = _resolveComponent("bags-card")!
  const _component_stamp_card = _resolveComponent("stamp-card")!
  const _component_keys_card = _resolveComponent("keys-card")!
  const _component_window_card = _resolveComponent("window-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: "specifics-popup container-fluid g-0",
    style: _normalizeStyle(_ctx.getStyles)
  }, [
    (this.specific.specifics.type === 'visitenkarte')
      ? (_openBlock(), _createBlock(_component_business_card, {
          key: 0,
          onInfoClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('infoClicked', $event))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'flyer')
      ? (_openBlock(), _createBlock(_component_flyer_card, {
          key: 1,
          onInfoClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'letterhead')
      ? (_openBlock(), _createBlock(_component_letterhead_card, {
          key: 2,
          onInfoClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'giftcard')
      ? (_openBlock(), _createBlock(_component_giftcard_card, {
          key: 3,
          onInfoClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'birthdaycard')
      ? (_openBlock(), _createBlock(_component_birthdaycard_card, {
          key: 4,
          onInfoClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'pencilselling')
      ? (_openBlock(), _createBlock(_component_pencilselling_card, {
          key: 5,
          onInfoClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'contract')
      ? (_openBlock(), _createBlock(_component_contract_card, {
          key: 6,
          onInfoClicked: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'achievement')
      ? (_openBlock(), _createBlock(_component_achievement_card, {
          key: 7,
          onInfoClicked: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'pavement')
      ? (_openBlock(), _createBlock(_component_pavement_card, {
          key: 8,
          onInfoClicked: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'rollup')
      ? (_openBlock(), _createBlock(_component_rollup_card, {
          key: 9,
          onInfoClicked: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'companysign')
      ? (_openBlock(), _createBlock(_component_companysign_card, {
          key: 10,
          onInfoClicked: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'coffeecup')
      ? (_openBlock(), _createBlock(_component_coffeecup_card, {
          key: 11,
          onInfoClicked: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'beachflag')
      ? (_openBlock(), _createBlock(_component_beachflag_card, {
          key: 12,
          onInfoClicked: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'pen')
      ? (_openBlock(), _createBlock(_component_pen_card, {
          key: 13,
          onInfoClicked: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'bag')
      ? (_openBlock(), _createBlock(_component_bags_card, {
          key: 14,
          onInfoClicked: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'stamp')
      ? (_openBlock(), _createBlock(_component_stamp_card, {
          key: 15,
          onInfoClicked: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'keys')
      ? (_openBlock(), _createBlock(_component_keys_card, {
          key: 16,
          onInfoClicked: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true),
    (this.specific.specifics.type === 'window')
      ? (_openBlock(), _createBlock(_component_window_card, {
          key: 17,
          onInfoClicked: _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('infoClicked'))),
          onDone: _ctx.done
        }, null, 8, ["onDone"]))
      : _createCommentVNode("", true)
  ], 4))
}