<template>
  <div class="ui-input_button">
    <div class="input">
      <input :class="{mobile: isMobile}" type="text" :placeholder="placeholder" v-model="model">
    </div>
    <div class="button" :class="{free: this.free === true, nfree: this.free === false}">
      <button @click="$emit('clickButton')" v-if="!loading">
        <span v-if="this.free === true" style="display: flex; gap: .5rem">Verfügbar</span>
        <span v-else>Prüfen</span>
      </button>
      <button class="loading-btn" v-if="loading">
        <div class="d-flex justify-content-center align-items-center">
       <div class="loadingio-spinner-spinner-3fn24un7fxj"><div class="ldio-ir7e7ibh1t">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
        </div>
      </button>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  props: ['placeholder', 'color', 'modelValue', 'free', 'loading'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
        this.$emit('change');
      }
    }
  }
}
</script>
<style scoped lang="scss">
@keyframes ldio-ir7e7ibh1t {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.ldio-ir7e7ibh1t div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-ir7e7ibh1t linear 1s infinite;
  background: #292929;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}

.ldio-ir7e7ibh1t div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #292929;
}

.ldio-ir7e7ibh1t div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #292929;
}

.loadingio-spinner-spinner-3fn24un7fxj {
  width: 48px;
  height: 48px;
  display: inline-block;
  overflow: hidden;
}

.ldio-ir7e7ibh1t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.48);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-ir7e7ibh1t div {
  box-sizing: content-box;
}


.ui-input_button {
  position: relative;

  button.loading-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      mix-blend-mode: multiply;
    }
  }


  input {
    -webkit-box-shadow: 0px 0px 15px 2px #878787;
    box-shadow: 0px 0px 5px -1px #878787;
    outline: none;
    border: 4px solid #dedede;
    width: 500px;
    padding: .5rem .5rem .5rem .5rem;
    background: white;
    border-radius: 30px;
    padding-right: 100px;

    &.mobile{
      width: 90vw;
    }

  }

  .button {
    position: absolute;
    top: 0;
    right: 0;

    &.free {
      button {
        background: var(--primary);
        color: white;
      }
    }

    button {
      background: linear-gradient(90deg, rgb(255, 223, 67) 0%, rgb(247, 196, 30) 100%);

      width: 40px;
      height: 48px;
      outline: none;
      border: none;
      border-radius: 8px;
      display: flex;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      padding: .5rem 3rem;
      font-size: 16px;
      gap: 2rem;
      font-weight: bold;
      cursor: pointer;
      color: #292929;
      font-size: 14px;
      box-shadow: 0 0 4px .5px rgba(0, 0, 0, 0.19);
    }
  }
}

</style>