<template>
  <div class="ui-radio" @click="model = !model" :class="{active: model === true}">
    <input type="checkbox" class="d-none" v-model="model" @change="$emit('change')">
  </div>
</template>
<script>

export default {
  name: 'ui-radio',
  props: ['modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    if(this.model === null){
      this.model = false;
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
div.ui-radio {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #a9a9a9;
  cursor: pointer;
  background: white;

  &.active{
    border: 7px solid var(--yellow);
    background: #292929;

  }
}
</style>
