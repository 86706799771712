import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28a9b762"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column container-fluid flow-page",
  style: {"height":"calc(100vh - 110px)"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "head col-12 position-fixed" }
const _hoisted_4 = {
  class: "row my-body",
  style: {"margin-top":"162px"}
}
const _hoisted_5 = { class: "content col-12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = {
  key: 0,
  class: "col-12 d-flex justify-content-center position-relative"
}
const _hoisted_9 = {
  key: 0,
  class: "prev-button d-flex align-items-center skip-button"
}
const _hoisted_10 = {
  key: 3,
  class: "next-button d-flex align-items-center skip-button"
}
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = {
  key: 1,
  class: "container-fluid"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = {
  class: "col-12",
  style: {"z-index":"2"}
}
const _hoisted_15 = {
  class: "col-12",
  style: {"margin-top":"160px"}
}
const _hoisted_16 = {
  class: "col-12 mt-3 mt-xxl-0 g-0",
  style: {"z-index":"1"}
}
const _hoisted_17 = {
  key: 0,
  class: "col-12"
}
const _hoisted_18 = {
  key: 0,
  class: "skip-buttons d-flex justify-content-between mt-4",
  style: {"gap":"1rem","margin-bottom":"calc(82px + 1rem)"}
}
const _hoisted_19 = { class: "prev d-flex justify-content-center" }
const _hoisted_20 = { class: "next d-flex justify-content-center" }
const _hoisted_21 = {
  key: 0,
  class: "row",
  style: {"z-index":"10"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_popup = _resolveComponent("info-popup")!
  const _component_ui_popup = _resolveComponent("ui-popup")!
  const _component_specifics_popup = _resolveComponent("specifics-popup")!
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_flow_head = _resolveComponent("flow-head")!
  const _component_ui_button_prev = _resolveComponent("ui-button-prev")!
  const _component_flow_cards = _resolveComponent("flow-cards")!
  const _component_flow_concept = _resolveComponent("flow-concept")!
  const _component_ui_button_next = _resolveComponent("ui-button-next")!
  const _component_footer_bar = _resolveComponent("footer-bar")!
  const _component_ui_button_prev_mobile = _resolveComponent("ui-button-prev-mobile")!
  const _component_ui_button_next_mobile = _resolveComponent("ui-button-next-mobile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_popup, {
      modelValue: _ctx.infoPopupState,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.infoPopupState) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_info_popup, { info: _ctx.infoPopupData }, null, 8, ["info"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ui_popup, {
      modelValue: _ctx.specificsPopupState,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.specificsPopupState) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_specifics_popup, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.specificsPopupState = false)),
          onInfoClicked: _ctx.infoClicked
        }, null, 8, ["onInfoClicked"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (!_ctx.isMobileXL)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_header_bar, { key: 0 }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["container-fluid flow", {mobile: _ctx.isMobile}])
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (!_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_flow_head, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  (!_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (!_ctx.loading)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              (_ctx.getCurrentStepIndex >= 0)
                                ? (_openBlock(), _createBlock(_component_ui_button_prev, {
                                    key: 0,
                                    class: "px-5",
                                    onClick: _ctx.prevClicked
                                  }, null, 8, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.loading)
                          ? (_openBlock(), _createBlock(_component_flow_cards, {
                              key: 1,
                              onClickInfo: _ctx.clickInfo,
                              onSpecific: _cache[3] || (_cache[3] = ($event: any) => (_ctx.specificsPopupState = true))
                            }, null, 8, ["onClickInfo"]))
                          : _createCommentVNode("", true),
                        (_ctx.loading)
                          ? (_openBlock(), _createBlock(_component_flow_concept, { key: 2 }))
                          : _createCommentVNode("", true),
                        (!_ctx.loading)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              (_ctx.canSkip())
                                ? (_openBlock(), _createBlock(_component_ui_button_next, {
                                    key: 0,
                                    state: _ctx.nextState(),
                                    class: "px-5",
                                    onNext: _ctx.next
                                  }, null, 8, ["state", "onNext"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ])
          ]),
          (_ctx.getCurrentStep.getType() !== 'pay' && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: _normalizeClass(["foot col-12 mt-auto py-3", {'has-price' : _ctx.getPrice > 0}])
                }, [
                  _createVNode(_component_footer_bar, {
                    ref: "footer",
                    onOpenSpecifics: _ctx.openSpecifics,
                    onSpecific: _cache[4] || (_cache[4] = ($event: any) => (_ctx.specificsPopupState = true))
                  }, null, 8, ["onOpenSpecifics"])
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_header_bar, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_15, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_flow_head, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_flow_cards, {
                    key: 0,
                    onClickInfo: _ctx.clickInfo,
                    onSpecific: _cache[5] || (_cache[5] = ($event: any) => (_ctx.specificsPopupState = true))
                  }, null, 8, ["onClickInfo"]))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_flow_concept, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.getCurrentStep.getName() !== 'pay')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (!_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          (_ctx.getCurrentStepIndex >= 0)
                            ? (_openBlock(), _createBlock(_component_ui_button_prev_mobile, {
                                key: 0,
                                onClick: _ctx.prevClicked
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          (_ctx.canSkip())
                            ? (_openBlock(), _createBlock(_component_ui_button_next_mobile, {
                                key: 0,
                                state: _ctx.nextState(),
                                onNext: _ctx.next
                              }, null, 8, ["state", "onNext"]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.getCurrentStep.getType() !== 'pay' && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                (_ctx.getPrice > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["foot col-12 mt-auto py-3", {'has-price' : _ctx.getPrice > 0, 'mobile' : _ctx.isMobile}]),
                      style: {"z-index":"10"}
                    }, [
                      _createVNode(_component_footer_bar, {
                        ref: "footer",
                        onOpenSpecifics: _ctx.openSpecifics,
                        onSpecific: _cache[6] || (_cache[6] = ($event: any) => (_ctx.specificsPopupState = true))
                      }, null, 8, ["onOpenSpecifics"])
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}