
import {Options, Vue} from 'vue-class-component';
import {mapGetters, mapMutations, mapState} from 'vuex';

@Options({
  name: 'header-bar',
  computed: {
    ...mapState(['steps', 'currentStep', 'visited2', 'hasEverLoaded']),
    ...mapGetters(['getCurrentStepIndex', 'getCurrentStep', 'getCurrentStepProgress', 'getNextStep']),
  },
  methods: {
    ...mapMutations(['next', 'previous', 'goto']),
    showMe() {
      if (!this.isMobileXL) {
        return true;
      }

      console.log(this.getCurrentStep.getName())

      const hidden = ['pay', 'pay-1', 'pay-2'];
      for (const hide of hidden) {
        if (hide === this.getCurrentStep.getName()) {
          return false;
        }
      }

      return true;
    },
    getMySteps() {
      const steps = [];
      for (const step of this.steps) {
        if (!step.skipInProgress) {
          steps.push(step);
        }
      }

      return steps;
    },
    calcProgress() {
      if (this.isMobileXL) {
        return {
          width: `calc(50vw - 6rem + ${this.getBabySteps() * 3}%)`
        };
      }

      let minus = 0;
      for (const step of this.steps) {
        if (step.skipInProgress) {
          minus--;
        }
      }

      let stepLength = this.steps.length - 1 + minus;
      let position = ((this.getCurrentStepIndex > stepLength ? stepLength : this.getCurrentStepIndex) / (stepLength)) * 100
      position += this.getBabySteps();

      return {
        width: `${Math.floor(position)}%`
      };
    },
    getBabySteps() {
      if (!this.isChild()) {
        return 0;
      }

      let minus = 0;
      for (const step of this.steps) {
        if (step.skipInProgress) {
          minus--;
        }
      }

      const diffs = 100 / (this.steps.length - 1 + minus);
      return ((this.getCurrentChildIndex() / (this.getChildLength() + 2)) * Math.floor(diffs));
    },
    getMobileBabySteps() {
      if (!this.isChild()) {
        return 0;
      }

      let minus = 0;
      for (const step of this.steps) {
        if (step.skipInProgress) {
          minus--;
        }
      }

      const diffs = 100 / (this.steps.length - 1 + minus);
      return ((this.getCurrentChildIndex()) * Math.floor(diffs));
    },
    isChild() {
      const stepName = this.getCurrentStep.getName();
      for (let step of this.steps) {
        if (step.name === stepName) {
          return false;
        }
      }

      return true;
    },
    getCurrentChildIndex() {
      const step = this.steps[this.getCurrentStepIndex];
      if (!step.childs) {
        return 0;
      }

      for (let i = 0; i < step.childs.length; i++) {
        if (step.childs[i].name === this.getCurrentStep.getName()) {
          return i + 1;
        }
      }

      return 0;
    },
    getNextStep() {
      return 0;
    },
    getChildLength() {
      const step = this.steps[this.getCurrentStepIndex];
      return step.childs.length;
    },
    jump(name: string) {
      if (this.hasEverLoaded) {
        return;
      }

      if (this.visited2.includes(name)) {
        this.goto(name);
      }
    }
  }
})
export default class HeaderBar extends Vue {
}
