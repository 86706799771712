
import {Options, Vue} from 'vue-class-component';
import {mapGetters, mapState} from "vuex";
import UiButton from "@/components/ui-button.vue";

@Options({
  name: 'ui-button-next-mobile',
  components: {UiButton},
  computed: {
    ...mapGetters(['getCurrentStep']),
    ...mapState(['steps']),
  },
  props: ['state'],
  data() {
    return {
      lastStep: 'socialmedia',
      shake: false,
    }
  },
  methods: {
    handleClick() {
      if (this.canSkip() && (this.getCurrentStep.getName() === this.lastStep) && this.getSelectedElementsCount() === 0) {
        this.$nextTick(() => {
          this.shake = true;

          setTimeout((() => {
            this.shake = false;
          }), 1000);
        })
      } else {
        this.$emit('next')
      }
    },
    readyForFinal() {
      return this.canSkip() && (this.getCurrentStep.getName() === this.lastStep) && this.getSelectedElementsCount() > 0
    },
    canSkip() {
      return true;
    },
    getSelectedElementsCount() {
      let count = 0;
      for (const step of this.steps) {
        if (!step.cards) {
          continue;
        }

        for (const card of step.cards) {
          if (card.price && card.selected) {
            count++;
          }
        }

        if (step.childs) {
          for (const cStep of step.childs) {
            if (!cStep.cards) {
              continue;
            }

            for (const card of cStep.cards) {
              if (card.price && card.selected) {
                count++;
              }
            }
          }
        }
      }

      return count;
    }
  }
})
export default class UiButtonNext extends Vue {
}
