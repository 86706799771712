export const screenMixin = {
    mounted() {
        window.addEventListener('resize', () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.windowWidth = window.innerWidth
        })
    },
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    computed: {
        getScreenSize(): number {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.windowWidth;
        },
        isMobile(): boolean {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.getScreenSize < 1000;
        },
        isMobileXL(): boolean {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.getScreenSize < 1300;
        }
    },
};
