
import {Options, Vue} from "vue-class-component";
import {FormParameter, Pipe} from "@/components/form-parameter";

@Options({
  name: "ui-form",
  props: ['parameter'],
  data() {
    return {
      formLoaded: false,
    }
  },
  updated() {
    if (this.formLoaded) {
      return;
    }
    this.formLoaded = true;
    let formParameter: FormParameter = this.parameter;
    if (!formParameter) {
      return;
    }
    for (let submit of formParameter.submit) {
      submit.ref.$el.addEventListener('click', this.submit);
    }
  },
  methods: {
    submit() {
      console.log(":)")
      this.$nextTick(() => {
        let formParameter: FormParameter = this.parameter;
        let readyToSubmit  = true;
        const submitTree: any = {};

        for (let parameter of formParameter.parameter) {
          let value = parameter.ref.model || '';

          let pipeResult = this.validatePipe(parameter.pipe, value);

          if (pipeResult !== null) {
            parameter.ref.invalidPipe(parameter.error || pipeResult);
            this.$emit('error', parameter);
            readyToSubmit = false;
          } else {
            parameter.ref.validPipe();
          }

          submitTree[parameter.name] = value;
        }

        if (readyToSubmit) {
          this.$emit('submit', submitTree);
        }
      });
    },
    validatePipe(pipe: Pipe, value: any): string | null {
      if ((value === undefined || value === null || value === '') && pipe.optional) {
        return null;
      }

      if (pipe.min && value.toString().length < pipe.min) {
        return `Mindestens ${pipe.min} Zeichen`;
      }

      if (pipe.max && value.toString().length > pipe.max) {
        return `Maximal ${pipe.max} Zeichen`;
      }

      if (pipe.isMail && !/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/i.test(value.toString())) {
        return `Muss eine gültige E-Mail Adresse sein`;
      }

      if (pipe.isPhoneNumber && !/^(?:(?:\+49|0|\\+49|0049)[\s-]*\d[\s-]*)|(?:\+41[\s-]*\d[\s-]*)|(?:\+43[\s-]*\d[\s-]*)$/i.test(value.toString())) {
        return `Muss eine gültige Telefonnummer sein`;
      }

      if (pipe.notEmpty && value.toString().length === 0) {
        return `Darf nicht leer sein`;
      }

      if (pipe.isObject && typeof value !== 'object') {
        return 'Muss ausgefüllt sein';
      }

      if (pipe.regex && (typeof value !== 'string' || !pipe.regex.test(value))) {
        return 'Ungültiges Format';
      }

      if (pipe.isColor && (typeof value !== 'string' || !/^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6}|[a-fA-F0-9]{8}|red|green|blue|yellow|black|white|cyan|magenta)$/.test(value))) {
        return 'Muss ausgefüllt sein';
      }

      return null;
    }
  }
})
export default class UiForm extends Vue {
}
