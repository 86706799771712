import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_theme = _resolveComponent("logo-theme")!
  const _component_print_theme = _resolveComponent("print-theme")!
  const _component_calc_theme = _resolveComponent("calc-theme")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info-popup container-fluid", {mobile: _ctx.isMobile}])
  }, [
    ($props.info.details.type === 'logo')
      ? (_openBlock(), _createBlock(_component_logo_theme, {
          key: 0,
          info: $props.info
        }, null, 8, ["info"]))
      : _createCommentVNode("", true),
    ($props.info.details.type === 'print')
      ? (_openBlock(), _createBlock(_component_print_theme, {
          key: 1,
          info: $props.info
        }, null, 8, ["info"]))
      : _createCommentVNode("", true),
    ($props.info.details.type === 'website')
      ? (_openBlock(), _createBlock(_component_print_theme, {
          key: 2,
          info: $props.info
        }, null, 8, ["info"]))
      : _createCommentVNode("", true),
    ($props.info.details.type === 'calc')
      ? (_openBlock(), _createBlock(_component_calc_theme, {
          key: 3,
          info: $props.info
        }, null, 8, ["info"]))
      : _createCommentVNode("", true)
  ], 2))
}