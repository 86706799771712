
import {Options, Vue} from 'vue-class-component';
import UiButton from "@/components/ui-button.vue";
import UiRadio from "@/components/ui-radio.vue";
import BusinessCard from "@/layout/popup/specifics/print/business-card.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import FlyerCard from "@/layout/popup/specifics/print/flyer-card.vue";
import LetterheadCard from "@/layout/popup/specifics/print/letterhead-card.vue";
import GiftcardCard from "@/layout/popup/specifics/print/giftcard-card.vue";
import BirthdaycardCard from "@/layout/popup/specifics/print/birthdaycard-card.vue";
import PencilsellingCard from "@/layout/popup/specifics/print/pencilselling-card.vue";
import ContractCard from "@/layout/popup/specifics/print/contract-card.vue";
import AchievementCard from "@/layout/popup/specifics/print/achievement-card.vue";
import PavementCard from "@/layout/popup/specifics/print/pavement-card.vue";
import RollupCard from "@/layout/popup/specifics/advertise/rollup-card.vue";
import CompanysignCard from "@/layout/popup/specifics/advertise/companysign-card.vue";
import CoffeecupCard from "@/layout/popup/specifics/advertise/coffeecup-card.vue";
import BeachflagCard from "@/layout/popup/specifics/advertise/beachflag-card.vue";
import PenCard from "@/layout/popup/specifics/advertise/pen-card.vue";
import BagsCard from "@/layout/popup/specifics/advertise/bags-card.vue";
import StampCard from "@/layout/popup/specifics/advertise/stamp-card.vue";
import KeysCard from "@/layout/popup/specifics/advertise/keys-card.vue";
import WindowCard from "@/layout/popup/specifics/advertise/window-card.vue";

@Options({
  name: 'specifics-popup',
  components: {
    WindowCard,
    KeysCard,
    StampCard,
    BagsCard,
    PenCard,
    BeachflagCard,
    CoffeecupCard,
    CompanysignCard,
    RollupCard,
    PavementCard,
    AchievementCard,
    ContractCard,
    PencilsellingCard,
    BirthdaycardCard, GiftcardCard, LetterheadCard, FlyerCard, BusinessCard, UiRadio, UiButton
  },
  computed: {
    ...mapState(['specific', 'specCard']),
    ...mapGetters(['getCurrentStep']),
    getStyles() {
      let width = 450;

      if (!this.isMobile) {
        return {
          width: `${width}px`,
        }
      } else {
        return {
          maxWidth: '550px',
          width: '100vw'
        }
      }
    }
  },
  props: [],
  methods: {
    ...mapMutations(['setSpecific']),
    done(obj: any) {
      this.setSpecific(null);

      this.specCard.selected = true;
      if (this.getCurrentStep.getType() === 'multi-select') {
        for (const card of this.getCurrentStep.getCards()) {
          if (card.selected && card.next) {
            card.selected = false;
          }
        }
      }


      this.specCard.specs.edited = true;
      this.specCard.specs.attr = obj.attr;
      this.specCard.specs.addon = obj.addon || null;
      this.specCard.specs.price = obj.price;
      this.specCard.specs.priceKind = 'one-time';
      this.specCard.specs.peaces = obj.peaces;

      this.$emit('close');
    }
  },
})
export default class SpecificsPopup extends Vue {
}
