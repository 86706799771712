import {StepSubbar} from "@/core/entity/step/subbar/step-subbar.entity";
import {store} from "../../../store/index";

export class Step {

    constructor(
        private readonly step: any
    ) {
    }

    public isUndefined() {
        return this.step === undefined || this.step === null;
    }

    public getRaw() {
        return this.step;
    }

    public getType() {
        return this.step['type'];
    }

    public hasGoTo() {
        return this.step['goto'] !== undefined;
    }

    public getName() {
        return this.step['name'];
    }

    public isIconActive() {
        return this.step['iconActive'];
    }

    public getIcon() {
        return this.step['icon'];
    }

    public getTitle() {
        return this.step['title'];
    }

    public getPrevSibling() {
        const steps = store.state.steps;
        const index = steps.findIndex((step: any) => step.name === this.step['name']) - 1;
        if (index >= 0) {
            return steps[index];
        }

        return null;
    }

    public getMyParentsChildren() {
        for (const step of store.state.steps) {
            if (step.childs) {
                for (const child of step.childs) {
                    if (child.name === this.step['name']) {
                        return step.childs;
                    }
                }
            }
        }
    }

    public getHeadline(name: string) {
        let headline = this.step['headline'];

        const lastVisited = store.state.visited;
        if (lastVisited.length > 0) {
            const last = lastVisited[lastVisited.length - 1];
            let step = this.step;
            for (const sStep of store.state.steps) {
                if (sStep.name === last) {
                    step = new Step(sStep);
                    break;
                }

                if (sStep.childs) {
                    for (const child of sStep.childs) {
                        if (child.name === last) {
                            step = new Step(child);
                        }
                    }
                }
            }

            let headlineChoice = false;
            for (const card of step.getCards()) {
                if (card.selected === true) {
                    headlineChoice = true;
                }
            }

            if (!headlineChoice) {
                headline = this.step['headline2'] || this.step['headline'] || '';
            }
        }

        return headline.replaceAll('$name', `<span class="name">${name}</span>`) || 'test';
    }

    public getSubLine(name: string) {
        return this.step['subLine'].replaceAll('$name', `<span class="name">${name}</span>`) || '';
    }

    public showSubLine() {
        return this.step['subLineMobile'] === undefined || this.step['subLineMobile'] === true;
    }

    public isChild() {
        for (const step of store.state.steps) {
            if (step.name === this.step['name']) {
                return false;
            }
        }

        return true;
    }

    public hasChilds() {
        return this.step['childs'] !== undefined;
    }

    public getChilds() {
        return this.step['childs'];
    }

    public hasSubBar(): boolean {
        return this.step['subBar'] !== undefined;
    }

    public getSubBar() {
        return new StepSubbar(this.step['subBar']);
    }

    public getCards() {
        return this.step['cards'] || [];
    }

}
