<template>
  <div class="ui-input">
    <input id="x" ref="input" type="text" :placeholder="placeholder" v-model="model" style="z-index: 9999">
    <i v-if="icon" :class="icon"></i>
  </div>
</template>
<script>

export default {
  name: "UiInput",
  props: ['icon', 'placeholder', 'modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
}
</script>
<style scoped lang="scss">
@import "@/core/components/ui/styles/styles";

div.ui-input {
  position: relative;
  display: flex;
  align-items: center;

  input {
    outline: none;
    color: black;
    width: 100%;
    padding: .5rem 2rem .5rem 30px;
    background: white;
    border: 2px solid var(--primary);
    border-radius: 8px;
  }

  i {
    position: absolute;
    font-size: 20px;
    left: 8px;
    color: grey;
    pointer-events: none; /* Dies verhindert, dass das Symbol interaktiv wird */
  }
}
</style>
