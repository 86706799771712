import {Ajax} from "@/@core/ajax/ajax";

export class Core {

    public ajax: Ajax | undefined;

    public loadCore() {
        this.loadAjax().then(() => {
            console.log("ajax load done");
        });
    }

    public async loadAjax() {
        this.ajax = new Ajax(this);
    }


}
