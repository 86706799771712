export class DateAgent {

    constructor(private readonly date: Date) {
        this.date = date;
    }

    public isValid() {
        return !isNaN(this.date.getTime());
    }

    public getTime() {
        return (this.date as Date).getTime();
    }

    public getFormattedDate(): string {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        };

        const formattedDate = this.date.toLocaleString("de-DE", options);

        return `${formattedDate}`;
    }

    public getFormattedDateTime(): string {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        };

        const formattedDate = this.date.toLocaleString("de-DE", options);
        return `${formattedDate}`;
    }

    public getDate(): Date {
        return this.date as Date;
    }

    public convertToHTMLDate() {
        return `${this.date.getFullYear()}-${(this.date.getMonth() + 1).toString().padStart(2, '0')}-${this.date.getDate().toString().padStart(2, '0')}T${this.date.getHours().toString().padStart(2, '0')}:${this.date.getMinutes().toString().padStart(2, '0')}`;
    }

    public formatTime(): string {
        const formattedHours = this.date.getHours().toString().padStart(2, "0");
        const formattedMinutes = this.date.getMinutes().toString().padStart(2, "0");

        return `${formattedHours}:${formattedMinutes} Uhr`;
    }

    public static getWeekdayString(day: number) {
        const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

        if (day >= 0 && day < days.length) {
            return days[day];
        } else {
            return 'Ungültiger Wochentag!';
        }
    }

}
