<template>
  <input type="checkbox" v-model="model" style="display: none">
  <div class="app-popup" v-if="model === true" :class="variety">
    <div class="popup-frame desktop" v-if="!isMobile">
      <div class="container-fluid g-0">
        <div class="row">
          <div class="col-12 d-flex justify-content-between" style="z-index: 500">
            <span class="popup-title">{{ title || '' }}</span>
            <i class="bi bi-x-lg close" @click="model = false; $emit('close')" style="z-index: 1000"></i>
          </div>
        </div>
        <slot>
        </slot>
      </div>
    </div>
    <div class="popup-frame mobile" v-if="isMobile">
      <div class="container-fluid g-0">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <span class="popup-title">{{ title || '' }}</span>
            <i class="bi bi-x-lg close" @click="model = false" style="z-index: 999"></i>
          </div>
        </div>
        <slot>

        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "app-popup",
  props: ['modelValue', 'title', 'variety'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  watch: {
    model() {
      if (this.model) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  },
  mounted() {

    document.addEventListener('keydown', (key) => {
      if (key.code === 'Escape') {
        this.model = false;
      }
    });
  },
  unmounted() {
    document.body.style.overflow = 'unset';
  }
}
</script>
<style lang="scss" scoped>
div.app-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.85);


  @keyframes fade-in {
    0% {
      transform: scale(0.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slide-in {
    0% {
      bottom: calc((100vh - 140px) * -1);
    }
    100% {
      bottom: 0
    }
  }

  div.popup-frame {
    background: white;
    z-index: 1000;
    border-radius: 20px;
    margin-left: -20px;

    i.close {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 10px;
      font-size: 32px;
      color: #292929;
      transition: .2s;

      &:hover {
        color: #777777;
      }
    }

    span.popup-title {
      color: var(--bright-grey);
    }

    &.desktop {
      border-radius: 20px;
      animation: .2s fade-in forwards;
    }

    &.mobile {
      position: relative;
      margin-left: 0;
      animation: .4s fade-in forwards;
      min-height: 200px;
      border-radius: 20px;
      padding-bottom: .7rem;
      max-width: 100vw;
      max-height: 720px;
    }
  }
}
</style>
