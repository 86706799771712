
import {Options, Vue} from 'vue-class-component';
import {mapGetters} from "vuex";

@Options({
  name: 'flow-head',
  computed: {
    ...mapGetters(['getCurrentStep', 'getFullName'])
  },
  methods: {
    showSubline() {
      return !this.isMobile || (this.isMobile && this.getCurrentStep.showSubLine());
    }
  }
})
export default class FlowHead extends Vue {
}
