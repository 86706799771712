<template>
  <div class="deek-checkbox" ref="checkbox" :class="variety">
    <div class="checkbox-wrapper-4">
      <input class="inp-cbx" :id="getUniqueId()" v-if="variety === 'disabled'" v-model="model" ref="input" disabled
             type="checkbox"/>
      <input class="inp-cbx" :id="getUniqueId()" v-else ref="input" type="checkbox" v-model="model"/>
      <label class="cbx" :for="getUniqueId()"><span style="color: var(--check-color)">
        <svg width="12px" height="10px">
          <use xlink:href="#check-4"></use>
        </svg></span></label>
      <svg class="inline-svg">
        <symbol id="check-4" viewbox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  </div>
</template>
<script>

export default {
  name: "deek-checkbox",
  props: ['color', 'modelValue', 'variety'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  data() {
    return {
      id: null,
    }
  },
  methods: {
    getUniqueId() {
      if (!this.id) {
        this.id = `el_${Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 1)) + 1}`;
      }
      return this.id;
    }
  },
  mounted() {
    let el = this.$refs.checkbox;
    el.style.setProperty('--check-color', this.color ? this.color : 'var(--primary)');
  }
}
</script>
<style lang="scss" scoped>

div.deek-checkbox {
  .checkbox-wrapper-4 * {
    box-sizing: border-box;
  }

  .checkbox-wrapper-4 {
    display: flex;
  }

  &.disabled {
    --check-color: grey !important;
  }

  .checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    border: 2px solid var(--bg-2);
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;
    transition: all 0.2s ease;
    background: transparent !important;
    display: inline-block;
  }

  .checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  .checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 22px;
    height: 22px;
    transform: scale(1.1);
    transition: all 0.2s ease;
  }

  .checkbox-wrapper-4 .cbx span:first-child svg {
    fill: none;
    stroke: var(--bg-1);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  .checkbox-wrapper-4 .cbx span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 18px;
  }

  .checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: var(--check-color) !important;
  }

  .checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
  }

  .inp-cbx:checked + .cbx {
    border: 2px solid var(--check-color);
  }

  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
    background: var(--check-color);
    animation: wave-4 0.2s ease;
  }

  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  .checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }

  @media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
      width: 100%;
      display: inline-block;
    }
  }

  @-moz-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }

  @-webkit-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }

  @-o-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }

  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
}
</style>
