import {Core} from "@/@core/core";
import {RequestParameter} from "@/@core/ajax/request/request-parameter";
import {Method} from "@/@core/ajax/request/_schema/method.enum";

export class Ajax {

    constructor(
        public core: Core
    ) {
        this.core = core;
    }

    public async fetch(requestParameter: RequestParameter) {
        const headers = this.buildHeader(requestParameter);
        const method = requestParameter.method;

        const protocol = 'https';
        const host = 'motion.mcbrands.de';
        const port = 3000;
        let url: string = this.buildUrl(protocol, host, requestParameter.url, port)

        const request: any = {headers, method};
        switch (method) {
            case Method.POST:
            case Method.DELETE:
            case Method.PUT:
                request['body'] = JSON.stringify(requestParameter.json);
                break;
            case Method.GET:
                if (requestParameter.json) {
                    const params = new URLSearchParams(requestParameter.json);
                    url += `?${params.toString()}`;
                }
                break;
        }

        const response = await fetch(url, request);
        if (!response.ok) {
            throw 'error on ajax fetch';
        }

        return await response.json();
    }

    private buildUrl(protocol: 'http' | 'https', host: string, path?: string, port?: number): string {
        const defaultPorts = {http: 80, https: 443};
        const defaultPort = defaultPorts[protocol];

        let url = `${protocol}://${host}`;
        if (port && port !== defaultPort) {
            url += `:${port}`;
        }
        if (path) {
            url += path.startsWith('/') ? path : `/${path}`;
        }
        return url;
    }


    private buildHeader(requestParameter: RequestParameter) {
        const defaultHeaders: any = {
            'Content-Type': 'application/json',
        }

        if (requestParameter.auth) {
            defaultHeaders['Authorization'] = `Bearer ${localStorage.getItem(`motion_jwt`)}`;
        }

        return defaultHeaders;
    }
}
