export interface RequestInterface {
    firstname: string;
    lastname: string;
    companyName: string;
    street: string;
    city: string;
    postcode: string;
    phone: string;
    cart: RequestCart;
    mailAddress: string
}

export interface RequestCart {

    items: CartItem[];
    payment: RequestPayment;
}

export enum PaymentType {
    ONETIME = 'onetime',
    INSTALLMENT = 'installment'
}

export enum InstallmentType {
    ONE = 'one',
    TWO = 'two',
    THREE = 'three',
    FOUR = 'four'
}

export interface RequestPayment {
    type: PaymentType;
    installmentType?: InstallmentType;
}

export enum Type {
    LOGO = 'logo',
    PRINT = 'print',
    ADVERTISE = 'advertise',
    WEBSITE = 'website',
    SOCIALMEDIA = 'socialmedia',
}

export interface CartItem {
    type: Type;
    specifics: any;
}