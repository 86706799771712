
import {Options, Vue} from "vue-class-component";
import HeaderBar from "@/layout/header-bar.vue";
import FooterBar from "@/layout/footer-bar.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import FlowCard from "@/view/flow/card/card.vue";
import UiButton from "@/components/ui-button.vue";
import UiInput from "@/components/ui-input.vue";
import UiInput_button from "@/components/ui-input_button.vue";
import UiButtonBack from "@/components/ui-button-back.vue";
import UiScrollInstruction from "@/components/ui-scroll-instruction.vue";
import InfoPopup from "@/layout/popup/info-popup.vue";
import SpecificsPopup from "@/layout/popup/specifics-popup.vue";
import FlowHead from "@/view/flow/parts/flow-head.vue";
import FlowCards from "@/view/flow/parts/flow-cards.vue";
import UiButtonNext from "@/components/ui-button-next.vue";
import UiButtonPrev from "@/components/ui-button-prev.vue";
import {LottieAnimation} from "lottie-web-vue";
import FlowPay from "@/view/flow/card/pay.vue";
import FlowConcept from "@/view/flow/card/concept.vue";
import {Step} from "@/core/entity/step/step.entity";
import UiButtonNextMobile from "@/components/ui-button-next-mobile.vue";
import UiButtonPrevMobile from "@/components/ui-button-prev-mobile.vue";


@Options({
  components: {
    UiButtonPrevMobile,
    UiButtonNextMobile,
    FlowConcept,
    FlowPay,
    LottieAnimation,
    UiButtonPrev,
    UiButtonNext,
    FlowCards,
    FlowHead,
    SpecificsPopup,
    InfoPopup,

    UiScrollInstruction, UiButtonBack, UiInput_button, UiInput, UiButton, FlowCard, FooterBar, HeaderBar
  },
  props: ['meta'],
  computed: {
    ...mapGetters(['getCurrentStep', 'getCurrentStepIndex', 'getFullName', 'getStepsLength', 'getPrice']),
    ...mapState(['visited', 'currentStep', 'av', 'steps', 'visited2', 'specific', 'loading', 'currentSpecificClicked', 'cartOpened']),
    ...mapState('specifics', ['specifics'])
  },
  data() {
    return {
      available: null,
      domain: '',
      infoPopupState: false,
      specificsPopupState: false,
      infoPopupData: null,
    }
  },
  mounted() {
    if (this.meta) {
      this.setName(atob(this.meta));
    }
  },
  watch: {
    specificsPopupState() {
      if (!this.specificsPopupState) {
        if (!this.currentSpecificClicked) {
          this.setSpecific(null);
        }

        if (this.cartOpened) {
          const footer = this.$refs.footer;
          footer.openPopup();
          this.setCartOpened(false);
        }
      }
    },
    infoPopupState() {
      if (!this.infoPopupState && this.currentSpecificClicked) {
        if (this.currentSpecificClicked) {
          this.setCurrentSpecificClicked(null)
          this.specificsPopupState = true;
        }
      }
    }
  },
  methods: {
    ...mapMutations(['next', 'previous', 'setName', 'setSpecific', 'setSpecCard', 'setCurrentSpecificClicked', 'setCartOpened']),
    openSpecifics() {
      this.specificsPopupState = true;
    },
    infoClicked() {
      this.specificsPopupState = false;
      this.infoPopupState = true;
      this.infoPopupData = this.currentSpecificClicked;
    },
    canSkip() {
      const step: Step = this.getCurrentStep;
      if (step.getRaw()['noSkip'] === true) {
        return false;
      }

      return true;
    },
    prevClicked() {
      if (this.getCurrentStepIndex === 0) {
        this.changeName();
        return;
      }

      this.previous();
    },
    nextState() {
      const step: Step = this.getCurrentStep;

      for (const card of step.getCards()) {
        if (card.selected === true) {
          return true;
        }
      }

      return !!(step.getName() === 'choose-domain' && this.av);
    },
    clickInfo(card: any) {
      this.infoPopupState = true;
      this.infoPopupData = card || {};
    },
    changeName() {
      this.$router.push('/name');
    },
  },
})
export default class Welcome extends Vue {
}
