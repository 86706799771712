<template>
  <div class="flow-single-card" :class="{mobile: isMobileXL}">
    <div class="flow-card d-flex flex-column align-items-center" @click="clickCard"
         :class="{active: card.selected || false}">
      <input type="text" class="d-none" v-model="model">
      <div class="edited" v-if="card.specs && card.specs.edited" @click="$emit('edit')" @mouseover="changeImage"
           @mouseleave="resetImage">
        <img :src="editedImage" alt="">
      </div>
      <div class="icon">
        <img src="@/assets/imgs/pages/no-choose.svg" v-if="card.next && card.selected" alt="">
        <img :src="card.icon" alt="" v-else>
      </div>
      <div class="title" :class="{skip: card.next, next: card.next && card.selected}" v-html="getCardTitle(card.title)">

      </div>
      <div class="peaces" v-if="this.card && this.card.specs && this.card.specs.peaces">
        {{ getCardPeaces }} Stück
      </div>
      <div class="peaces" v-if="this.card && this.card.specs && this.card.specs.addon">
        {{ this.card.specs.addon }}
      </div>
      <div class="price text-center mt-1">
        <span v-if="getCardPrice">
         <span v-if="getCardPriceKind === 'from'" style="font-weight: normal">ab</span> {{ formatPrice }} <span
            v-if="getCardPriceKind === 'monthly'">mntl.</span>
        </span>
      </div>
      <div class="sub-title" :class="{mobile: isMobile}" style="white-space: nowrap" v-if="card.subText" v-html="getCardSubtitle(card.subText)">
      </div>
    </div>
    <div class="icons align-items-center gap-1 d-flex justify-content-center mt-3" v-if="info !== undefined || info"
         :class="{mobile: isMobileXL}"
         @click="$emit('clickInfo')">
      <div v-if="info.icons.info">Produktinfo</div>
      <div v-if="info.icons.calc">Rechenbeispiel</div>
      <div class="icon d-flex gap-4">
        <img src="@/assets/imgs/pages/info.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "flow-card",
  props: ['card', 'modelValue', 'info'],
  computed: {
    ...mapGetters(['getPrice']),
    getCardPeaces() {
      if (this.card.specs && this.card.specs.peaces) {
        return this.card.specs.peaces;
      }

      return this.card.peaces;
    },
    getCardPrice() {
      if (this.card.specs && this.card.specs.price) {
        return this.card.specs.price;
      }

      return this.card.price;
    },
    getCardPriceKind() {
      if (this.card.specs && this.card.specs.priceKind) {
        return this.card.specs.priceKind;
      }

      return this.card.priceKind;
    },
    formatPrice() {
      return `${this.getCardPrice}€`;
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  data() {
    return {
      editedImage: require('@/assets/imgs/pages/edit.svg')
    };
  },
  methods: {
    formatMyPrice(priceNum, showFloor = true) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      });

      let forReturn = formatter.format(priceNum).replace(/\s/g, "");
      if (!showFloor) {
        forReturn = forReturn.split(",")[0];
      }

      return forReturn;
    },
    getCardTitle(title) {
      const brutto = this.getPrice + this.getPrice * 0.19;
      title = title.replace('$percent25', `<span style="color: var(--primary)">${this.formatMyPrice(brutto * 0.25)}</span>`);
      return title;
    },
    getCardSubtitle(subTitle) {
      const brutto = this.getPrice + this.getPrice * 0.19;

      subTitle = subTitle.replace('$price_rate1', this.formatMyPrice(brutto * 0.75));
      subTitle = subTitle.replace('$price_rate2', this.formatMyPrice((brutto * 0.75) / 2));
      subTitle = subTitle.replace('$price_rate3', this.formatMyPrice((brutto * 0.75) / 3));
      subTitle = subTitle.replace('$price_rate4', this.formatMyPrice((brutto * 0.75) / 4));
      return subTitle
    },
    clickCard() {
      this.model = !this.model || false
      this.$emit('clickCard')
    },
    changeImage() {
      this.editedImage = require("@/assets/imgs/pages/edited-purple.svg");
    },
    resetImage() {
      this.editedImage = require("@/assets/imgs/pages/edit.svg");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/core/components/ui/styles/styles";

div.flow-single-card {
  div.icons {
    cursor: pointer;

    div.icon {
      cursor: pointer;

      img {
        height: 20px
      }
    }

    &.mobile {
      font-size: 14px;
    }
  }

  div.flow-card {
    border: 3px solid $grey;
    border-radius: 20px;
    width: 180px;
    height: 180px;
    padding: .5rem;
    cursor: pointer;
    position: relative;

    div.edited {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 20px;
      color: grey;
      transition: .2s;

      img {
        height: 40px;
        width: 40px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    div.icon {
      margin-top: -5px;

      img {
        padding: 1rem;
        height: 90px;
        width: 90px;
      }
    }

    div.peaces {
      color: var(--secondary);
      font-size: 17px
    }

    div.title {
      font-size: 15px;
      text-align: center;
      font-weight: bold;


      &.skip {
        font-weight: normal;
        color: #adadad;
      }

      &.next {
        color: #000;
      }
    }

    div.price {
      font-size: 16px;
      font-weight: bold;
    }

    div.sub-title {
      font-size: 15px;
      text-align: center;

      &.mobile{
        font-size: 14px;

      }
    }

    &:hover {
      background: rgba(242, 242, 244, 0.38);
      border-color: rgba(41, 41, 41, 0.32);
    }

    &.active {
      background: #eaeaea;
      border-color: #292929;
    }

  }

  &.mobile {
    margin-bottom: 1rem;

    .flow-card {
      width: 170px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      div.peaces {
        color: var(--secondary);
        font-size: 15px
      }

      div.price {
        font-size: 16px;
        font-weight: bold;
      }

      div.edited {
        img {
          height: 30px;
          width: 30px;
        }
      }

      div.icon {
        margin-top: -14px;

        img {
          padding: 1rem;
          height: 75px;
          width: 75px;
        }
      }


      div.title {
        font-size: 15px;
        text-align: center;
        font-weight: bold;


        &.skip {
          font-weight: normal;
          color: #adadad;
        }

        &.next {
          color: #000;
        }
      }
    }
  }
}
</style>
