
import {Options, Vue} from 'vue-class-component';

@Options({
  name: 'image-preview',
  props: ['image'],
  components: {},
  data() {
    return {
      showZoomView: false,
      mouseX: 0,
      mouseY: 0,
      zoomAreaSize: 120,
      zoomFactor: 1.2,
      disableZoom: true,
    };
  },
  computed: {
    popperStyle() {
      const zoomFactor = 0.95; // 90% zoom on body
      if (!this.$refs.image) {
        return {};
      }

      return {
        left: `${(this.mouseX + this.$refs.image.offsetLeft) / zoomFactor}px`,
        top: `${(this.mouseY + this.$refs.image.offsetTop) / zoomFactor}px`,
      };
    },
  },
  methods: {
    zoomAreaStyle() {
      const zoomFactor = 0.95;
      return {
        left: `${(this.mouseX - this.zoomAreaSize / 2) / zoomFactor}px`,
        top: `${(this.mouseY - this.zoomAreaSize / 2) / zoomFactor}px`,
        width: `${this.zoomAreaSize / zoomFactor}px`,
        height: `${this.zoomAreaSize / zoomFactor}px`,
      };
    },
    zoomedImageStyle() {
      if (!this.$refs.image) {
        return {};
      }

      const imageRect = this.$refs.image.getBoundingClientRect();
      const relativeMouseX = this.mouseX / imageRect.width;
      const relativeMouseY = this.mouseY / imageRect.height;

      const imgWidth = this.$refs.image.naturalWidth;
      const imgHeight = this.$refs.image.naturalHeight;

      const zoomFactor = 0.95;
      const zoomedImageX = Math.max(0, Math.min(imgWidth * this.zoomFactor * relativeMouseX, imgWidth * this.zoomFactor));
      const zoomedImageY = Math.max(0, Math.min(imgHeight * this.zoomFactor * relativeMouseY, imgHeight * this.zoomFactor));


      const dropdownWidthOffset = `calc(50% - ${zoomedImageX / zoomFactor}px)`;
      const dropdownHeightOffset = `calc(50% - ${zoomedImageY / zoomFactor}px)`;

      return {
        left: dropdownWidthOffset,
        top: dropdownHeightOffset,
        transform: `scale(${this.zoomFactor})`,
        transformOrigin: '0 0',
      };
    },
    handleMouseMove(event: any) {
      if (this.disableZoom) {
        return;
      }

      //disable image preview for mobile devices
      if (this.isMobile) {
        return;
      }

      this.showZoomView = true;
      const zoomFactor = 0.95; // 90% zoom on body
      const rect = this.$refs.image.getBoundingClientRect();
      this.mouseX = ((event.clientX - rect.left) / zoomFactor) + 75;
      this.mouseY = ((event.clientY - rect.top) / zoomFactor) + 30;
    },
    handleMouseLeave(event: any) {
      if (this.disableZoom) {
        return;
      }

      if (this.isMobile) {
        return;
      }

      this.showZoomView = true;
      const zoomFactor = 0.95; // 90% zoom on body
      const rect = this.$refs.image.getBoundingClientRect();
      this.mouseX = (event.clientX - rect.left) / zoomFactor;
      this.mouseY = (event.clientY - rect.top) / zoomFactor;
    },
  },
})
export default class ImagePreview extends Vue {
}
