
import {Options, Vue} from 'vue-class-component';
import UiRadio from "@/components/ui-radio.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import UiButton from "@/components/ui-button.vue";
import SpecificsPopup from "@/layout/popup/specifics-popup.vue";

@Options({
  name: 'cart-theme',
  components: {SpecificsPopup, UiButton, UiRadio},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getCurrentStep', 'getItems', 'getPrice', 'getSteps']),
    ...mapState('specifics', ['specifics']),
    ...mapState(['visited', 'currentStep', 'steps', 'visited2', 'specific']),
    getStyles() {
      let width = 550;

      if (!this.isMobile) {
        return {
          width: `${width}px`
        }
      } else {
        return {
          width: '100vw'
        }
      }
    }
  },
  methods: {
    ...mapMutations(['next', 'previous', 'setName', 'setSpecific', 'setSpecCard', 'goto']),
    unselectItem(item: any) {
      item.selected = false
      if (item.specs) {
        item.specs = {
          edited: false
        }
      }

      for (const step of this.getSteps) {
        if (step.req) {
          for (const req of step.req) {
            const exists = this.getSteps.find((sStep: any) => sStep.name === req);
            if (!exists) {
              const i1 = this.visited.findIndex((v: any) => v === step.name);
              this.visited.splice(i1, 1);

              for (const card of step.cards) {
                card.selected = false
                if (card.specs) {
                  card.specs = {
                    edited: false
                  }
                }
              }

              this.goto(req);

            }
          }
        }
      }

      if (this.getPrice === 0) {
        const stepsGoBack = ['pay', 'pay-1', 'pay-2'];
        for (const step of stepsGoBack) {
          if (this.getCurrentStep.getName() === step) {
            this.goto('logo');
          }
        }
        this.$emit('close');
      }
    },
    formatPrice(priceNum: number, showFloor = true) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      });

      let forReturn = formatter.format(priceNum).replace(/\s/g, "");
      if (!showFloor) {
        forReturn = forReturn.split(",")[0];
      }

      return forReturn;
    },
    getSpecificByCardName(cardName: string) {
      for (const spec of this.specifics) {
        if (spec.name === cardName) {
          return spec;
        }
      }
      return null;
    },
    getItemPrice(item: any) {
      let myPrice = item.price;
      if (item.specs && item.specs.edited && item.specs.price) {
        myPrice = item.specs.price;
      }

      return myPrice;
    },
    editItem(item: any) {
      const specifics = this.getSpecificByCardName(item.name);
      if (specifics) {
        if (item.specs.edited) {
          this.setSpecCard(item);
          specifics['specifics']['attr'] = item.specs.attr;
          this.setSpecific(JSON.parse(JSON.stringify(specifics)));
          this.$emit('openSpecifics')
          return;

        }
        this.setSpecCard(item);
        this.setSpecific(JSON.parse(JSON.stringify(specifics)));
        this.$emit('openSpecifics')
        return;
      }
    },
    clickCard() {
      this.model = !this.model || false
      this.$emit('clickCard')
    },
  }
})
export default class BagsCard extends Vue {

}
