import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e52d4114"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 g-0" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "pros"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.info !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["infos", {mobile: _ctx.isMobile}])
          }, [
            _createElementVNode("div", {
              class: "headline mt-3",
              innerHTML: _ctx.replaceVariables(_ctx.info.details.title) || 'FlowERR: Title unset'
            }, null, 8, _hoisted_3),
            (_ctx.info.details && _ctx.info.details.pro)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.details.pro, (pro) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "pro",
                      key: pro
                    }, [
                      _createElementVNode("div", {
                        class: "text",
                        innerHTML: _ctx.replaceVariables(pro.text)
                      }, null, 8, _hoisted_5)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "subline mb-3",
              innerHTML: _ctx.info.details.subtitle || ''
            }, null, 8, _hoisted_6)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: _normalizeClass(["technical-details", {mobile: _ctx.isMobile}])
          }, [
            _createElementVNode("div", {
              class: "subline mt-1",
              innerHTML: _ctx.info.details.technicalDetails || 'FlowERR: Technical-Details unset'
            }, null, 8, _hoisted_8)
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}