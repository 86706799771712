
import {Options, Vue} from "vue-class-component";
import UiButton from "@/components/ui-button.vue";
import UiInput from "@/components/ui-input.vue";
import {mapMutations} from "vuex";
import {LottieAnimation} from "lottie-web-vue";

@Options({
  name: 'name',
  components: {LottieAnimation, UiInput, UiButton},
  data() {
    return {
      myName: '',
      shake: false,
      originalBodyStyle: '',
    }
  },
  methods: {
    ...mapMutations(['setName']),
    calcHeight() {
      return "calc(100% - 70px)"
    },
    go() {
      if (this.myName.trim().length < 2) {
        this.shake = true;
        setTimeout((() => {
          this.shake = false;
        }), 1000);
        return;
      }

      const myName = this.myName;
      const name = btoa(myName.charAt(0).toUpperCase() + myName.slice(1));
      this.$router.push(`/flow/${name}`);
    },
    changeMyName() {
      this.setName(this.myName);
    }
  }
})
export default class welcome extends Vue {

}
