
import {Options, Vue} from "vue-class-component";
import UiButton from "@/components/ui-button.vue";

@Options({
  name: 'test',
  components: {UiButton},
  methods: {
    go() {
      this.$router.push('/name');
    }
  },
})
export default class welcome extends Vue {

}
