<template>
    <div class="ui-button-back">
        <button :class="{'secondary' : this.secondary !== undefined, 'third' : this.third !== undefined, 'disabled' : this.disabled === true}">
            <i class="bi bi-arrow-left"></i>
            <i v-if="icon !== undefined" :class="icon"/>
        </button>
    </div>
</template>
<script>
export default {
    name: "ui-button-back",
    props: ['secondary', 'third', 'disabled', 'icon'],
}
</script>
<style lang="scss" scoped>
div.ui-button-back {


  button {
    background: var(--border-bright-grey);
    color: var(--secondary);
    width: 48px;
    height: 48px;
    outline: none;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 2rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 4px .5px rgba(0, 0, 0, 0.19);

    i {
      font-size: 24px;
    }

    &:hover {
      transform: translateY(-4px);
    }

    &.disabled {
      background: rgba(138, 141, 147, .1);
      color: var(--bright-grey);
      cursor: not-allowed;

      &:hover {
        background: rgba(138, 141, 147, 0.2);
        transform: unset;
      }

    }

    &.secondary {
      background: #f1f1f1;
      color: var(--secondary);


      &:hover {
        background: rgba(138, 141, 147, 0.3);
      }

      i {
        margin-left: unset;
      }
    }

    &.third {
      justify-content: space-between;
      padding: 0 .8rem !important;
    }
  }
}
</style>
