
import {Options, Vue} from "vue-class-component";

@Options({
  mounted() {
    document.addEventListener('touchmove', this.preventZoom);
    document.addEventListener('wheel', this.preventZoomWheel);
  },
  beforeDestroy() {
    document.removeEventListener('touchmove', this.preventZoom);
    document.removeEventListener('wheel', this.preventZoomWheel);
  },
  methods: {
    preventZoom(event: any) {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    },
    preventZoomWheel(event: any) {
      event.preventDefault();
    }
  },
})
export default class App extends Vue {
}
