import {createStore} from 'vuex'
import {Step} from "@/core/entity/step/step.entity";
import specifics from "@/store/specifics.store";

export const store = {
    modules: {
        specifics
    },
    state: {
        av: null,
        name: '',
        currentStep: 'logo',
        loading: false,
        hasEverLoaded: false,
        visited: [],
        visited2: ['logo'],
        specific: null,
        specCard: null,
        currentSpecificClicked: null,
        cartOpened: false,
        steps: [
            {
                name: 'logo',
                title: 'Logo',
                type: 'single-select',
                headline: '$name, schön Dich kennenzulernen! <br class="d-none d-lg-block">Das Herzstück jeder erfolgreichen Marke ist das <span style="color: var(--primary)">Logo</span>. 🎨',
                headline2: '',
                subLine: 'Starte mit einem einprägsamen Logo die Verwandlung zur eigenen Marke.',
                subLine2: '',
                subBar: {
                    type: 'print',
                    first: 'inkl. individuelles Wunsch-Design',
                    second: 'inkl. professioneller Brandguide',
                    third: 'inkl. aller Dateiformate',
                },
                icon: require('@/assets/imgs/slidebar/inactive/Logo-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Logo-neu.svg'),
                cards: [
                    {
                        price: 199.00,
                        forceSkip: true,
                        title: 'Ich brauche ein neues Logo',
                        icon: require('@/assets/imgs/steps/logo/Neues-Logo-Produkt.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Individuell gestaltetes Logo',
                            img: require('@/assets/imgs/steps/logo/info/Logo-neu.jpg'),
                            pro: [
                                {
                                    text: '<b>Unbegrenzte Revisionsrunden</b>, bist Du zufrieden bist'
                                },
                                {
                                    text: 'Professioneller <b>Kundensupport</b> während des gesamten Prozesses'
                                }
                            ],
                            subtitle: 'Gemeinsam finden wir den idealen Logo-Typ, die passende Schriftart und die perfekte Farbe, die Dein Mobile-Store zum Strahlen bringt.',
                            technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"><b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass <br class="d-none d-lg-block"> Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                        },
                    },
                    {
                        price: 139.00,
                        forceSkip: true,
                        title: 'Mein Logo braucht eine Auffrischung',
                        icon: require('@/assets/imgs/steps/logo/Logo-Redesign-Produkt.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            img: require('@/assets/imgs/steps/logo/info/Logo-Redesign-neu.jpg'),
                            title: 'Auffrischung Deines Logo',
                            pro: [
                                {
                                    text: '<b>Unbegrenzte Revisionsrunden</b>, bist Du zufrieden bist'
                                },
                                {
                                    text: 'Professioneller <b>Kundensupport</b> während des gesamten Prozesses'
                                }
                            ],
                            subtitle: 'Optimiere Dein Logo mit unserer Experten-Beratung: Wir analysieren gemeinsam jedes Detail und identifizieren gezielt Verbesserungspotenzial.',
                            technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"> <b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass <br class="d-none d-lg-block"> Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                        },
                    },
                ]
            },
            {
                name: 'print',
                title: 'Print',
                type: 'multi-select',
                headline: 'Super! Nun zu Einheitlichkeit + Weltklasse Design. <br class="d-none d-lg-block"> Wie präsentierst Du Deine Marke auf <span style="color: var(--primary)">Papier</span>?🌟',
                headline2: 'Einheitlichkeit + Weltklasse Design. <br class="d-none d-lg-block"> Wie präsentierst Du Deine Marke auf <span style="color: var(--primary)">Papier</span>?🌟',
                subLine: 'Um Deine Botschaft zu kommunizieren, braucht Deine Marke ausdrucksstarke Druckprodukte.',
                subLine2: 'Du musst Dich um nichts kümmern',
                subBar: {
                    type: 'print',
                    first: 'alles inkl. Designkosten',
                    second: 'alles inkl. Druckkosten',
                    third: 'Rückgaberecht bei Mängeln',
                },
                icon: require('@/assets/imgs/slidebar/inactive/Print-neu .svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Print-neu .svg'),
                cards: [
                    {
                        price: 79,
                        priceKind: 'from',
                        peaces: 250,
                        forceSkip: false,
                        title: 'Visitenkarten',
                        name: 'visitenkarte',

                        icon: require('@/assets/imgs/steps/print/Visitenkarte-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            img: require('@/assets/imgs/steps/print/info/Visitenkarte-neu.jpg'),
                            type: 'logo',
                            title: 'Individuell gestaltete Visitenkarte',
                            pro: [
                                {
                                    text: '<b>Stabiler Karton</b>, brillanter Druck'
                                },
                                {
                                    text: '<b>Schutzfolie</b> auf beiden Seiten'
                                },
                                {
                                    text: 'Blitzschneller <b>Versand</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Visitenkarte:</b> <br class="d-none d-lg-block">Format: 8,5 x 5,4cm, Papier: 350g ChromoNorm2 <br class="d-none d-lg-block"> Druck: 4/4-farbig Euroskala + 2-seitig Schutzfolie'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 129.00,
                        priceKind: 'from',
                        peaces: 500,
                        forceSkip: false,
                        title: 'Flyer 2-seitig',
                        name: 'flyer',

                        icon: require('@/assets/imgs/steps/print/Flyer 2-Seitig-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Maßgeschneiderter Flyer',
                            img: require('@/assets/imgs/steps/print/info/Flyer-2-Seitig-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinen eigenen <b>Angeboten</b> und <b>Aktionen</b>'
                                },
                                {
                                    text: 'Zu Deinen <b>Anlässen</b> (Eröffnung, Jubiläum, Black Friday usw.)'
                                },
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Flyers:</b> <br class="d-none d-lg-block"> Format: 10,0 x 21,0cm (DIN Lang),<br class="d-none d-lg-block"> Druck: 4/4-farbig Euroskala + 2-seitig Schutzlack<br class="d-none d-lg-block"> Papier: 250g Bilderdruck (FSC-Mix Crédit)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 145.00,
                        priceKind: 'from',
                        peaces: 500,
                        forceSkip: false,
                        title: 'Briefbogen',
                        name: 'letterhead',

                        icon: require('@/assets/imgs/steps/print/Briefbogen-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            img: require('@/assets/imgs/steps/print/info/Briefbogen-neu.jpg'),
                            title: 'Überzeugender Firmen-/Briefbogen<br class="d-none d-lg-block">(inkl. Wordvorlage)',
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                },
                                {
                                    text: 'Hochwertiges <b>umweltfreundliches</b> Papier'
                                },
                                {
                                    text: 'Mit allen relevanten <b>Firmendaten</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Briefbogen:</b> <br class="d-none d-lg-block">Format: 21,0 x 29,7cm (DIN A4), Druck: 4/0-farbig Euroskala,<br class="d-none d-lg-block"> Papier: 90g Laser Offset (FSC-Mix 70%)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 119.00,
                        priceKind: 'from',
                        peaces: 250,
                        forceSkip: false,
                        title: 'Geschenkgutschein',
                        name: 'giftcard',

                        icon: require('@/assets/imgs/steps/print/Geschenkgutschein-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Außergewöhnlicher Geschenkgutschein',
                            img: require('@/assets/imgs/steps/print/info/Geschenkgutschein-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                },
                                {
                                    text: '<b>Stabiler Karton</b>, brillanter Druck'
                                },
                                {
                                    text: 'Auf einer Seite <b>beschreibbar</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Geschenkgutscheins:</b> <br class="d-none d-lg-block"> Format: 14,8 x 10,5cm (DIN A6 quer)<br class="d-none d-lg-block"> Druck: 5/4-farbig Euroskala + 1-seitig Hochglanzlack<br class="d-none d-lg-block"> Papier: 300g ChromoKarton (FSC-Mix 70%)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 119.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Geburtstagskarte',
                        name: 'birthdaycard',

                        icon: require('@/assets/imgs/steps/print/Geburstagskarte-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            img: require('@/assets/imgs/steps/print/info/Geburtstagskarte-neu.jpg'),
                            type: 'logo',
                            title: 'Beeindruckende Geburtstagskarte',
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                },
                                {
                                    text: '<b>Stabiler Karton</b>, brillanter Druck'
                                },
                                {
                                    text: 'Auf einer Seite <b>beschreibbar</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Geburtstagskarte:</b> <br class="d-none d-lg-block"> Format: 14,8 x 10,5cm (DIN A6 quer)<br class="d-none d-lg-block"> Druck: 5/4-farbig Euroskala + 1-seitig Hochglanzlack<br class="d-none d-lg-block"> Papier: 300g ChromoKarton (FSC-Mix 70%)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 199.00,
                        priceKind: 'from',
                        peaces: 100,
                        forceSkip: false,
                        title: 'P. Selling-Block',
                        name: 'pencilselling',

                        icon: require('@/assets/imgs/steps/print/Pencilsellingblock-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            img: require('@/assets/imgs/steps/print/info/Pencil-Selling-neu.jpg'),
                            type: 'logo',
                            title: 'Effizienter Pencil-Selling-Block',
                            pro: [
                                {
                                    text: 'Vorgefertigter <b>strukturierter</b> Gesprächsablauf'
                                },
                                {
                                    text: 'Alle <b>Kundeninformationen</b> auf einen Blick'
                                },
                                {
                                    text: 'Offizielle Wirkung durch <b>Unterschrift</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Pencil-Selling-Block:</b> <br class="d-none d-lg-block"> Format: 21,0 x 29,7cm (DIN A4),<br class="d-none d-lg-block"> Druck: 4/0-farbig Euroskala, Inhalt: 25 Blatt (kariert),<br class="d-none d-lg-block"> Papier: 90g Laser Offset (FSC-Mix 70%)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 199.00,
                        priceKind: 'from',
                        peaces: 100,
                        forceSkip: false,
                        title: 'Vertrags-Mappe',
                        name: 'contract',

                        icon: require('@/assets/imgs/steps/print/Vertragsmappe-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            img: require('@/assets/imgs/steps/print/info/Vertragsmappe-neu.jpg'),
                            type: 'logo',
                            title: 'Hochwertige Vertragsmappe',
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                },
                                {
                                    text: 'Ohne Füllhöhe (bis <b>5 Blatt</b> DIN A4 Inhalt möglich)'
                                },
                                {
                                    text: 'Mit 5 mm Füllhöhe (bis <b>30 Blatt</b> DIN A4 Inhalt möglich)'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Vertragsmappe:</b> <br class="d-none d-lg-block"> Format: 21,5 x 30,0cm (passend für Inhalt DIN A4),<br class="d-none d-lg-block"> Druck: 5/0-farbig Euroskala + Hochglanzlack,<br class="d-none d-lg-block"> Papier: 235g ChromoKarton'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 75.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Leistungs-Poster',
                        name: 'achievement',

                        icon: require('@/assets/imgs/steps/print/Leistungsposter-A2-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Erfolgsversprechendes Leistungs-Poster',
                            img: require('@/assets/imgs/steps/print/info/Leistungsposter-neu.jpg'),
                            pro: [
                                {
                                    text: 'Deine <b>individuellen Serviceleistungen</b> auf einen Blick'
                                },
                                {
                                    text: 'Poster passt <b>perfekt</b> in den Klapprahmen'
                                },
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Leistungs-Posters:</b> <br class="d-none d-lg-block"> Format: 42,0 x 59,4cm (DIN A2), <br class="d-none d-lg-block"> Druck: 4/0-farbig Euroskala, Papier: 170g Bilderdruck matt digital (FSC-Mix Crédit) <b>Klapprahmen:</b> Metall Silber (Eloxiertes Aluminium), Rahmen 25 mm breit, abgerundete Ecken, <b>Inklusiv:</b> Schrauben und Dübel'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 99.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Werbe-Poster A1',
                        name: 'pavement',

                        icon: require('@/assets/imgs/steps/print/A1-Aufsteller-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Vielfältiges Werbe-Poster',
                            img: require('@/assets/imgs/steps/print/info/Werbeposter-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinen eigenen <b>Angeboten</b> und <b>Aktionen</b>'
                                },
                                {
                                    text: 'Poster passt <b>perfekt</b> in den Kunden-Stopper'
                                },
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Werbe-Poster:</b> <br class="d-none d-lg-block">Format: 59,4 x 84,1cm (DIN A1), Druck: 4/0-farbig Euroskala,<br class="d-none d-lg-block"> Papier: 170g Bilderdruck matt digital (FSC-Mix Crédit) <br class="d-none d-lg-block"><b>Kunden-Stopper:</b> Metall Silber, Format 62,4 x 87,0cm, mit Plastikabdeckung'
                        },
                        specs: {
                            edited: false,
                        }
                    },

                ]
            },
            {
                name: 'advertisement',
                title: 'Werbemittel',
                type: 'multi-select',
                headline: 'Klasse $name! Hebe Dich von Deiner Konkurrenz noch weiter ab! <br class="d-none d-lg-block"> Werbe mit Deinen eigenen <span style="color: var(--primary)">Werbemitteln</span>. 📣',
                headline2: 'Falle auf $name! Hebe Dich von Deiner Konkurrenz ab! <br class="d-none d-lg-block"> Werbe mit Deinen eigenen <span style="color: var(--primary)">Werbemitteln</span>. 📣',
                subLine: 'Es ist Zeit, groß zu denken und für Deine Marke die volle Aufmerksamkeit zu gewinnen.',
                subLine2: 'Du musst Dich um nichts kümmern',
                icon: require('@/assets/imgs/slidebar/inactive/Werbemittel-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Werbemittel-neu.svg'),
                subBar: {
                    type: 'print',
                    first: 'alles inkl. Designkosten',
                    second: 'alles inkl. Druckkosten',
                    third: 'Rückgaberecht bei Mängeln',
                },
                cards: [
                    {
                        price: 95.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Roll-Up',
                        name: 'rollup',
                        icon: require('@/assets/imgs/steps/advertisement/Roll-Up-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Ansprechendes Roll-Up',
                            img: require('@/assets/imgs/steps/advertisement/info/Roll-Up-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinen eigenen <b>Angeboten</b> und <b>Aktionen</b>'
                                },
                                {
                                    text: 'stabil und <b>sofort aufstellbar</b>'
                                },
                                {
                                    text: 'inkl. <b>passender Tasche</b> für den Transport'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Roll-Up:</b> <br class="d-none d-lg-block">Format: 85 x 200cm, Druck: 4/0-farbig Euroskala, Material: 510g Frontlit B1 (PVC)<br class="d-none d-lg-block"> Ausführung: Gehäuse Silber Basic'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 139.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Beach-Flag',
                        name: 'beachflag',
                        icon: require('@/assets/imgs/steps/advertisement/Beachflag-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Attraktives Beach-Flag',
                            img: require('@/assets/imgs/steps/advertisement/info/Beach-Flag-2.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinen eigenen <b>Angeboten</b> und <b>Aktionen</b>'
                                },
                                {
                                    text: 'inkl. Bodenplatte, stabil, <b>sofort aufstellbar</b>'
                                },
                                {
                                    text: '<b>wetterfest</b> und für jede Jahreszeit geeignet'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Beach-Flag:</b> <br class="d-none d-lg-block">Format: S = 67 x 210cm, M = 68 x 314cm, L = 75 x 440cm,<br class="d-none d-lg-block"> Druck: 4/0-farbig Euroskala, Material: 115g gewebtes Polyester,<br class="d-none d-lg-block"> Ausführung: Saum weiß, linke Seite, Drehrichtung rechts,<br class="d-none d-lg-block"> inklusiv Glasfaserstab und passender Tasche'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 60.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Stempel',
                        name: 'stamp',
                        icon: require('@/assets/imgs/steps/advertisement/Stempel-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Exklusiver Firmenstempel',
                            img: require('@/assets/imgs/steps/advertisement/info/Stempel.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und <b>Geschäftsinformationen</b> gebrandet'
                                },
                                {
                                    text: '<b>sofort einsetzbar</b>, du brauchst kein zusätzliches Stempelkissen'
                                },
                                {
                                    text: '<b>langlebig</b>, Stempelkissen einfach austauschbar'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Firmenstempel:</b> <br class="d-none d-lg-block">Größe: 64 x 26mm, Ausführung: Trodat Printy,<br class="d-none d-lg-block">Material: Kunststoff, Stempelfarben: schwarz/blau/rot/grün'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 149.00,
                        priceKind: 'from',
                        peaces: 100,
                        forceSkip: false,
                        title: 'Kugelschreiber',
                        name: 'pen',
                        icon: require('@/assets/imgs/steps/advertisement/Kugelschreiber-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Stilvolle Kugelschreiber',
                            img: require('@/assets/imgs/steps/advertisement/info/Kugelschreiber-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> gebrandet'
                                },
                                {
                                    text: 'auf verschiedenen <b>Oberflächen</b> einsetzbar'
                                },
                                {
                                    text: 'Finger-Stopper mit <b>unterschiedlichen Farben</b> erhältlich'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Kugelschreiber:</b> <br class="d-none d-lg-block">Kunststoff, 10g, 4/0-farbig, Länge x Breite = 135 x 15 mm, Mienenfarbe: blau'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 95.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Kaffee-Tassen',
                        name: 'coffeecup',
                        icon: require('@/assets/imgs/steps/advertisement/Tassen.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Exquisite Kaffee-Tassen',
                            img: require('@/assets/imgs/steps/advertisement/info/Kaffee-Tasse.jpg'),
                            pro: [
                                {
                                    text: 'Aus <b>edlem Keramik</b> und mit <b>Deinem Logo</b> gebrandet '
                                },
                                {
                                    text: '<b>Große Füllmenge</b> 340 ml'
                                },
                                {
                                    text: 'Spülmaschinenfest'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Kaffee-Tassen:</b> <br class="d-none d-lg-block">Druckformat: 210 x 90 mm (rundum außen),<br class="d-none d-lg-block"> 4/0-farbig Sublimation, Grundfarbe weiß'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 209.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Schlüsselbänder',
                        name: 'keys',
                        icon: require('@/assets/imgs/steps/advertisement/Schlüsselanhänger.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            img: require('@/assets/imgs/steps/advertisement/info/Schlüsselanhänger-neu.jpg'),
                            title: 'Einzigartige Schlüsselbänder',
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> und Deinen <b>Firmenfarben</b> gebrandet'
                                },
                                {
                                    text: 'Schlüssel ist durch die Steckschnalle <b>flexibel abnehmbar</b>'
                                },
                                {
                                    text: 'angenehm zu tragen, <b>stabil</b> und <b>reißfest</b>'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Schlüsselbänder:</b> <br class="d-none d-lg-block"> Material: Polyester, Grundfarbe weiß, Format: 20 x 500 mm,<br class="d-none d-lg-block"> Druck: 4/4-farbig, Ausführung: mit Steckschnalle'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 335.00,
                        priceKind: 'from',
                        peaces: 250,
                        forceSkip: false,
                        title: 'Papiertragetaschen',
                        name: 'bag',
                        icon: require('@/assets/imgs/steps/advertisement/Papiertragetasche-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            img: require('@/assets/imgs/steps/advertisement/info/Papiertragetsche.jpg'),
                            title: 'Umweltfreundliche Papiertragetaschen',
                            pro: [
                                {
                                    text: 'Mit Deinem <b>Logo</b> gebrandet'
                                },
                                {
                                    text: 'aus besonders <b>stabilem</b> Kraftpapier'
                                },
                                {
                                    text: '<b>100% Nachhaltig</b> und Recycelbar'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deiner Papiertragetaschen:</b> <br class="d-none d-lg-block"> Größe: S = 18 x 8 x 22 (B x T x H) - passend für Handy und Zubehör<br class="d-none d-lg-block"> Größe: M = 26 x 12 x 35 (B x T x H) - passend für Vertragsmappe, Handy oder Tablet<br class="d-none d-lg-block"> Material: 100g Kraftpapier, 1/0-farbig Pantone, Ausführung: Tragegriff Flachhenkel'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 75.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Fensterschild',
                        name: 'window',
                        icon: require('@/assets/imgs/steps/advertisement/Ladenschild.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Werbewirksames Fensterschild',
                            img: require('@/assets/imgs/steps/advertisement/info/Fensterschild-neu.jpg'),
                            pro: [
                                {
                                    text: 'Mit Deinen eigenen <b>Angeboten</b> und <b>Aktionen</b>'
                                },
                                {
                                    text: 'Zu <b>Deinen Anlässen</b> (Eröffnung, Jubiläum, Black Friday usw.)'
                                },
                                {
                                    text: 'immer <b>wieder verwendbar</b> durch leichte Handhabung mit Saugnäpfen'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Fensterschild:</b> <br class="d-none d-lg-block"> Größe: S = 100 x 70 (B x H), Größe: M = 140 x 50 (B x H),<br class="d-none d-lg-block"> Größe: L = 140 x 100 (B x H), Druck: 4/0-farbig Euroskala, 3,5 mm Polypropylen Hohlkammerplatte (vertikal)'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                    {
                        price: 95.00,
                        priceKind: 'from',
                        peaces: 1,
                        forceSkip: false,
                        title: 'Ladenschild',
                        name: 'companysign',
                        icon: require('@/assets/imgs/steps/advertisement/Ladenschild-neu.svg'),
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Maßgefertigtes Ladenschild',
                            img: require('@/assets/imgs/steps/advertisement/info/Ladenschild-neu.jpg'),
                            pro: [
                                {
                                    text: '<b>einfache Montage</b>, vorgebohrt, inkl. Abstandshalter (4 Stück)'
                                },
                                {
                                    text: '<b>wetterfest</b>, für innen und außen geeignet'
                                },
                                {
                                    text: '<b>Langlebigkeit</b> durch hochwertiges 3mm Alu-Dibond'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Technische Daten Deines Ladenschildes:</b> <br class="d-none d-lg-block"> Material: Dibond digital, 4/0-farbig bedruckt, Stärke: 3mm,<br class="d-none d-lg-block"> <b style="color: var(--primary)">Wichtig:</b> Du benötigst vier Schrauben und Dübel'
                        },
                        specs: {
                            edited: false,
                        }
                    },
                ]
            },
            {
                name: 'website',
                title: 'Website',
                type: 'single-select',
                headline: 'Jawoll! Wie wäre es nun mit einer professionell gestalteten <br class="d-none d-lg-block"><span style="color: var(--primary)">Website</span>, die neue Kunden anzieht. <span class="d-none d-lg-inline">💻</span>',
                headline2: 'Überragende Online-Präsenz mit einer professionell gestalteten <br class="d-none d-lg-block"><span style="color: var(--primary)">Website</span>, die neue Kunden anzieht. <span class="d-none d-lg-inline">💻</span>',
                subLine: 'Steigere Deine Sichtbarkeit, erweitere Deine Reichweite - mach Dich bereit für den digitalen Auftritt Deiner Marke.',
                subLine2: 'Möchtest Du alternativ mit einer professionellen Website nicht nur Kunden aus Deiner Region begeistern, sondern auch überregional zu einer Anlaufstelle für Mobile-Lösungen werden? 🚀🌎',
                subLineMobile: false,
                subBar: {
                    type: 'website',
                    text: '🎁 Domain & Hosting 12 Monate geschenkt! Danach nur 24,95€ mtl.',
                },
                icon: require('@/assets/imgs/slidebar/inactive/Website-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Website-neu.svg'),
                cards: [
                    {
                        title: 'Leistungsstarke Website',
                        icon: require('@/assets/imgs/steps/website/Landing-Page-neu.svg'),
                        childLink: 0,
                        price: 999.00,
                        priceKind: 'fix',
                        goto: 'webaddon',
                        info: {
                            icons: {info: true, picture: true}
                        },
                        details: {
                            type: 'logo',
                            title: 'Leistungsstarke Website',
                            img: require('@/assets/imgs/steps/website/info/Laningpage-neu.jpg'),
                            pro: [
                                {
                                    text: 'inkl. <b>redaktioneller Texte</b>'
                                },
                                {
                                    text: 'inkl. <b>unbegrenzter Stockbilder</b> (Lizensierte Bilder)',
                                    mobile: false,
                                },
                                {
                                    text: 'inkl. <b>Impressum</b> und <b>Datenschutzerklärung</b>'
                                },
                                {
                                    text: 'inkl. Einrichtung des <b>Cookie Hinweis</b> nach DSGVO-Vorschrift'
                                },
                                {
                                    text: 'inkl. Erstellung der <b>mobilen</b> (responsiven) Version (414 px)',
                                    mobile: false,
                                },
                                {
                                    text: 'inkl. einmalige <b>Ladezeitoptimierung</b>',
                                    mobile: false,
                                },
                                {
                                    text: 'inkl. <b>Sicherheitsupdates</b>, Spam- und Virenprüfung'
                                },
                                {
                                    text: 'inkl. <b>fortlaufende Aktualisierung</b> bei rechtlichen Änderungen'
                                }
                            ],
                            subtitle: '',
                            technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"> <b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass<br class="d-none d-lg-block">Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                        },
                    },
                ],
                childs: [
                    {
                        name: 'webaddon',
                        title: 'Zusatz',
                        type: 'multi-select',
                        req: ['website'],
                        goto: 'choose-domain',
                        headline: 'Super Entscheidung $name! Optimiere Deine Website mit <br class="d-none d-lg-block"> <span style="color: var(--primary)">Zusatzleistungen</span> für maximale Leistung und Sichtbarkeit.',
                        headline2: 'Super Entscheidung $name! Optimiere Deine Website mit <br class="d-none d-lg-block"> <span style="color: var(--primary)">Zusatzleistungen</span> für maximale Leistung und Sichtbarkeit.',
                        subLine: 'Mache es Deinen Kunden so einfach wie möglich, mit Deinem Geschäft zu interagieren.',
                        subLine2: '',
                        subBar: {
                            type: 'print',
                            first: 'inkl. technischer Aufsetzung',
                            second: 'keine monatlichen Zusatzkosten',
                            third: '24/7 Kundensupport',
                        },
                        cards: [
                            {
                                price: 149.00,
                                priceKind: 'fix',
                                peaces: 1,
                                forceSkip: false,
                                title: 'WhatsApp Live Chat',
                                icon: require('@/assets/imgs/steps/website/WhatsApp-LiveChat.svg'),
                                info: {
                                    icons: {info: true, picture: true}
                                },
                                details: {
                                    type: 'logo',
                                    title: 'Implementierung direkter WhatsApp-Chat',
                                    img: require('@/assets/imgs/steps/website/info/WhatsApp-Chat-neu.jpg'),
                                    pro: [
                                        {
                                            text: '<b>Sofortige</b> Kundenkommunikation über Deine Geschäftsnummer'
                                        },
                                        {
                                            text: 'verbesserte <b>Kundenservice-Erfahrung</b> durch schnellere Reaktionszeiten'
                                        },
                                        {
                                            text: '<b>automatisierte Antworten</b> außerhalb der Geschäftszeiten'
                                        }
                                    ],
                                    subtitle: 'Gib Deinen Kunden die Möglichkeit der direkten und schnellen Kontaktaufnahme per WhatsApp.',
                                    technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"><b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass<br class="d-none d-lg-block">Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                                },
                            },
                            {
                                price: 199.00,
                                priceKind: 'fix',
                                peaces: 1,
                                forceSkip: false,
                                title: 'Terminbuchungs Tool',
                                icon: require('@/assets/imgs/steps/website/Terminbuchungstool.svg'),
                                info: {
                                    icons: {info: true, picture: true}
                                },
                                details: {
                                    type: 'logo',
                                    title: 'Planungssicheres Terminbuchungs-Tool',
                                    img: require('@/assets/imgs/steps/website/info/Terminbuchungstool-neu.jpg'),
                                    pro: [
                                        {
                                            text: 'Deine Kunden können <b>jederzeit</b> und von <b>überall</b> aus Termine buchen <br class="d-none d-lg-block"> nicht nur während der Geschäftszeiten.'
                                        },
                                        {
                                            text: 'inkl. <b>automatische Terminerinnerung</b> per E-Mail oder SMS'
                                        },
                                        {
                                            text: 'synchronisiert sich reibungslos mit allen gängigen <b>Kalendersystemen</b>'
                                        }
                                    ],
                                    subtitle: 'Praktische Funktion, die es Deinen Kunden ermöglicht einen festen Termin in Echtzeit in Deinem Kalender zu buchen.',
                                    technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"><b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass<br class="d-none d-lg-block">Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                                },
                            },
                            {
                                price: 299.00,
                                priceKind: 'fix',
                                peaces: 1,
                                forceSkip: false,
                                title: 'Einmalige SEO-Optimierung',
                                icon: require('@/assets/imgs/steps/website/SEO-Onpage.svg'),
                                info: {
                                    icons: {info: true, picture: true}
                                },
                                details: {
                                    type: 'logo',
                                    title: 'Einmalige SEO-Onepage-Optimierung',
                                    img: require('@/assets/imgs/steps/website/info/SEO-On-Page-neu.jpg'),
                                    pro: [
                                        {
                                            text: 'Höhere <b>Sichtbarkeit in Suchmaschinen</b>, sodass potenzielle <br class="d-none d-lg-block"> Kunden Dich besser finden'
                                        },
                                        {
                                            text: 'Erhöhte <b>Glaubwürdigkeit</b> und <b>Autorität</b> gegenüber Deinen Wettbewerbern'
                                        },
                                        {
                                            text: 'Verbesserte <b>Nutzererfahrung</b> durch strukturierte Verlinkung'
                                        }
                                    ],
                                    subtitle: 'Wir positionieren Deine Webseite zu <b>relevanten Suchbegriffen</b> in der organischen Google Suche. Studien belegen, dass Du Deinen <b>Umsatz</b> durch professionelles SEO um <b>40% steigern</b> kannst.',
                                    technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"><b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass<br class="d-none d-lg-block">Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                                },
                            },
                            {
                                price: 1299.00,
                                priceKind: 'fix',
                                peaces: 1,
                                forceSkip: false,
                                title: 'Professionelles 90 Sek. Image-Video',
                                icon: require('@/assets/imgs/steps/website/info/90-Sek.svg'),
                                info: {
                                    icons: {info: true, picture: true}
                                },
                                details: {
                                    type: 'logo',
                                    title: 'Professionelles 90 Sek. Image-Video',
                                    img: require('@/assets/imgs/pages/Laningpage-Video-min.jpg'),
                                    pro: [
                                        {
                                            text: 'Mit deiner Persönlichkeit vermittelst du kurz und prägnant <br class="d-none d-lg-block">deine <b>Kernbotschaft</b>.'
                                        },
                                        {
                                            text: 'Durch die Kombination von Bild und Ton baust du eine <br class="d-none d-lg-block"><b>starke emotionale Bindung</b> zu deinen Kunden auf.'
                                        },
                                        {
                                            text: 'Mit deinem professionellen Video erhöhst du <b>Reichweite</b> und demonstrierst <br class="d-none d-lg-block"> deine Kommunikationsfähigkeit.'
                                        }
                                    ],
                                    subtitle: 'Zusammengefasst wird Dein <b>90-Sekunden-Imagevideo</b> ein kraftvolles Werkzeug sein, um Deine Botschaft zu vermitteln, die Engagement-Rate zu erhöhen und das Image Deiner Marke zu <b>stärken</b>. Es ist eine Investition, die sowohl die <b>Online-Präsenz</b> als auch die allgemeine Markenwahrnehmung positiv beeinflusst.',
                                    technicalDetails: 'Die Einzelheiten werden im Rahmen des <br class="d-none d-lg-block"><b>Beratungsgesprächs</b> geklärt - so stellen wir sicher, dass<br class="d-none d-lg-block">Du mit Deiner Auswahl <b>völlig zufrieden</b> bist!'
                                },
                            },
                        ],
                    },
                    {
                        name: 'has-domain',
                        title: 'has-domain',
                        req: ['website'],
                        type: 'single-select',
                        headline: 'Perfekt! Sollen wir für Dich eine einzigartige <br class="d-none d-lg-block">und prägnante <span style="color: var(--primary)">Domain</span> reservieren? 🌐',
                        headline2: 'Perfekt! Sollen wir für Dich eine einzigartige <br class="d-none d-lg-block">und prägnante <span style="color: var(--primary)">Domain</span> reservieren? 🌐',
                        subLineMobile: false,
                        subLine: 'Eine Domain ist mehr als nur eine Adresse, es ist Dein erster Eindruck Deiner Marke.',
                        subLine2: '',
                        subBar: {
                            type: 'print',
                            first: '12 Monate kostenlos',
                            second: 'inkl. Passender Business E-Mail Adressen',
                            third: 'schneller Aktivierung',
                        },
                        cards: [
                            {
                                title: 'Ja, bitte',
                                forceSkip: true,
                                subText: 'Kostenlos in Deinem <br> Hosting enthalten.',
                                icon: require('@/assets/imgs/steps/has-domain/Domian-Option.svg'),
                                price: 0,
                                goto: 'choose-domain',
                                info: {
                                    icons: {info: true, picture: false}
                                },
                                details: {
                                    type: 'logo',
                                    title: '1 x kostenlose Wunschdomain',
                                    img: require('@/assets/imgs/steps/website/info/Domain-neu.jpg'),
                                    pro: [
                                        {
                                            text: '12 Monate <b>kostenlos</b>',
                                        },
                                        {
                                            text: 'inkl. passender <b>Business E-Mail Adressen</b>'
                                        },
                                        {
                                            text: 'inkl. <b>SSL</b> Wildcard Zertifikat'
                                        },
                                        {
                                            text: 'inkl. Hilfe bei <b>technischer Aussetzung</b>'
                                        }
                                    ],
                                    subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                                    technicalDetails: 'Auf Wunsch <b>kostenloser</b> Kündigungsservice und Umzugsservice bisheriger Domains und Postfächer'
                                },
                            },
                            {
                                title: 'Nein, danke',
                                forceSkip: true,
                                subText: 'Im Moment nicht.',
                                icon: require('@/assets/imgs/steps/Nicht auswählen.svg'),
                                price: 0,
                                goto: 'socialmedia',
                                details: {
                                    type: 'logo',
                                    title: '1 x kostenlose Wunschdomain',
                                    img: require('@/assets/imgs/steps/website/info/Domain-neu.jpg'),
                                    pro: [
                                        {
                                            text: '12 Monate <b>kostenlos</b>',
                                        },
                                        {
                                            text: 'inkl. passender <b>Business E-Mail Adressen</b>'
                                        },
                                        {
                                            text: 'inkl. <b>SSL</b> Wildcard Zertifikat'
                                        },
                                        {
                                            text: 'inkl. Hilfe bei <b>technischer Aussetzung</b>'
                                        }
                                    ],
                                    subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                                    technicalDetails: 'Auf Wunsch <b>kostenloser</b> Kündigungsservice und Umzugsservice bisheriger Domains und Postfächer'
                                },
                            },
                        ],
                    },
                    {
                        name: 'choose-domain',
                        title: 'Choose Domain',
                        req: ['website'],
                        type: 'domain',
                        headline: 'Großartig $name! <br class="d-none d-lg-block">Welche <span style="color: var(--primary)">Wunsch-Domain</span> sollen wir für Dich registrieren? 🌍',
                        headline2: 'Großartig $name! <br class="d-none d-lg-block">Welche <span style="color: var(--primary)">Wunsch-Domain</span> sollen wir für Dich registrieren? 🌍',
                        goto: 'socialmedia',
                        subLine: 'Dein digitaler Identitätsstempel wartet auf Dich.',
                        subLine2: 'Und um das Ganze abzurunden, welche Wunsch-Domain sollen wir für Dich registrieren?',
                        subLineMobile: false,
                        subBar: {
                            type: 'print',
                            first: '12 Monate kostenlos',
                            second: 'inkl. SSL-Wildcard - Zertifikat',
                            third: 'inkl. Passender Business E-Mail Adressen',
                        },
                    },
                ]

            },
            {
                name: 'socialmedia',
                title: 'Social Media',
                type: 'multi-select',
                headline: 'Alles klar $name. Zum Schluss, auf welchen <span style="color: var(--primary)">Social Media</span> Plattformen <br class="d-none d-lg-block"> siehst Du Deine Marke dominieren? <span class="d-none d-lg-inline">📲</span>',
                headline2: 'Alles klar, $name. 🫡<br class="d-none d-lg-block"> Wie wäre es stattdessen, mit <span style="color: var(--primary)">Social Media</span> den Einfluss <br class="d-none d-lg-block">Deiner Marke zu stärken? <span class="d-none d-lg-inline">📲</span>',
                subLine: 'Nutze die Macht der sozialen Netzwerke und bringe Deine Marke direkt zu Deinem Publikum.',
                subLine2: '\'Sollen wir hingegen Social-Media-Accounts professionell für Dich einrichten, damit Deiner ersten Sichtbarkeit keine Grenzen gesetzt sind? 🚀',
                subBar: {
                    type: 'print',
                    first: 'inkl. technischer Aufsetzung',
                    second: 'keine monatlichen Zusatzkosten',
                    third: '24/7 Kundensupport',
                },
                icon: require('@/assets/imgs/slidebar/inactive/Social Media-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Social Media-neu.svg'),
                cards: [
                    {
                        price: 199.00,
                        forceSkip: false,
                        title: 'Google My Business',
                        icon: require('@/assets/imgs/steps/socialmedia/Google-my-Business.svg'),
                        info: {
                            icons: {info: true, picture: false}
                        },
                        details: {
                            type: 'logo',
                            title: 'Aufsetzung von Google My Business',
                            img: require('@/assets/imgs/steps/socialmedia/info/Google-neu.jpg'),
                            pro: [
                                {
                                    text: 'inkl. Übernahme des <b>vollständigen Prozesses</b> der Verifizierung <br class="d-none d-lg-block"> Deines Business-Accounts'
                                },
                                {
                                    text: 'inkl. individualisierter Gestaltung von <b>Profilbild</b> und <b>Titelbild</b>'
                                },
                                {
                                    text: '<b>Einpflegung aller relevanten Informationen <br class="d-none d-lg-block"></b> (Adresse, Tel, Öffnungszeiten, usw.)'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Nutze Google My Business</b>, <br class="d-none d-lg-block"> um die direkte Auffindbarkeit Deiner Marke auf Google Maps zu gewährleisten, sodass Kunden Dich und Deine Dienstleistungen schnell und präzise finden können.'
                        },
                    },
                    {
                        price: 99.00,
                        forceSkip: false,
                        title: 'Facebook',
                        icon: require('@/assets/imgs/steps/socialmedia/Facebook.svg'),
                        info: {
                            icons: {info: true, picture: false}
                        },
                        details: {
                            type: 'logo',
                            img: require('@/assets/imgs/steps/socialmedia/info/Facebook-Bild-neu.jpg'),
                            title: 'Aufsetzen einer Facebook Business-Seite',
                            pro: [
                                {
                                    text: 'inkl. Übernahme des <b>vollständigen Prozesses</b> der Verifizierung <br class="d-none d-lg-block"> Deiner Business-Seite'
                                },
                                {
                                    text: 'inkl. individualisierter Gestaltung von <b>Profilbild</b> und <b>Titelbild</b>'
                                },
                                {
                                    text: 'inkl. <b>Einpflegung aller relevanten Informationen</b><br class="d-none d-lg-block"> (Adresse, Tel, Website, usw.)'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Setze Facebook, mit seiner beeindruckenden Anzahl von über 2,8 Milliarden aktiven Nutzern, für Deinen Mobile-Store ein</b>,<br class="d-none d-lg-block"> um mit Deinen Kunden zu interagieren  und so wertvolle Kundebeziehungen aufzubauen!'
                        },
                    },
                    {
                        price: 99.00,
                        forceSkip: false,
                        title: 'Instagram',
                        icon: require('@/assets/imgs/steps/socialmedia/Instagram.svg'),
                        info: {
                            icons: {info: true, picture: false}
                        },
                        details: {
                            type: 'logo',
                            title: 'Aufsetzen eines Instagram Business Accounts',
                            img: require('@/assets/imgs/steps/socialmedia/info/Instagram-neu.jpg'),
                            pro: [
                                {
                                    text: 'inkl. Übernahme des <b>vollständigen Prozesses</b> der Verifizierung <br class="d-none d-lg-block"> Deines Business-Accounts'
                                },
                                {
                                    text: 'inkl. individualisierter Gestaltung von <b>Profilbild</b> und <b>5 Highlights</b>'
                                },
                                {
                                    text: 'inkl. <b>Einpflegung aller relevanten Informationen</b><br class="d-none d-lg-block"> (Adresse, Tel, Öffnungszeiten, usw.)'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Nutze Instagram mit seinen beeindruckenden 1,4 Milliarden aktiven Nutzern für Deinen Mobile-Store</b>,<br class="d-none d-lg-block"> um Deine Marke durch ansprechende visuelle Inhalte zum Leben zu erwecken und so ein engagierten Kundenstamm aufzubauen!'
                        },
                    },
                    {
                        price: 99.00,
                        forceSkip: false,
                        title: 'WhatsApp Business',
                        icon: require('@/assets/imgs/steps/socialmedia/WhatsApp-Business.svg'),
                        info: {
                            icons: {info: true, picture: false}
                        },
                        details: {
                            type: 'logo',
                            title: 'Aufsetzung von WhatsApp Business',
                            img: require('@/assets/imgs/steps/socialmedia/info/WhatsApp-Icon-neu.jpg'),
                            pro: [
                                {
                                    text: 'inkl. Übernahme des <b>vollständigen Prozesses</b> der Verifizierung <br class="d-none d-lg-block"> Deines Business-Accounts'
                                },
                                {
                                    text: 'inkl. individualisierter Gestaltung von <b>Profilbild</b> und <b>Titelbild</b>'
                                },
                                {
                                    text: '<b>Einpflegung aller relevanten Informationen <br class="d-none d-lg-block"></b> (Adresse, Tel, Öffnungszeiten, usw.)'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Setze WhatsApp Business für Deinen Mobile-Store ein</b>, <br class="d-none d-lg-block"> und biete Deinen Kunden die schnellste Support-Möglichkeit auf dem Markt - denn niemand hat heutzutage Lust, lange auf Antwortmails zu warten.'
                        },
                    },
                    {
                        price: 69.00,
                        forceSkip: false,
                        title: 'TikTok',
                        icon: require('@/assets/imgs/steps/socialmedia/Tik-Tok.svg'),
                        info: {
                            icons: {info: true, picture: false}
                        },
                        details: {
                            type: 'logo',
                            title: 'Aufsetzen eines TikTok Business Accounts',
                            img: require('@/assets/imgs/steps/socialmedia/info/TikTok.jpg'),
                            pro: [
                                {
                                    text: 'inkl. Übernahme des <b>vollständigen Prozesses</b> der Verifizierung <br class="d-none d-lg-block"> Deines Business-Accounts'
                                },
                                {
                                    text: 'inkl. individualisierter Gestaltung eines <b>Profilbildes</b>'
                                },
                                {
                                    text: 'inkl. einprägsamer <b>Bio-Text</b> Beschreibung'
                                }
                            ],
                            subtitle: 'Alle Einzelheiten werden im Rahmen des Beratungsgesprächs geklärt - so stellen wir sicher, dass Du mit Deiner Auswahl völlig zufrieden bist!',
                            technicalDetails: '<b>Entfessele die Kraft von TikTok und seiner unglaublichen Gemeinschaft von über 1 Milliarde aktiven Nutzern für Deinen Mobile-Store</b>,<br class="d-none d-lg-block"> um durch kreative Kurzvideos und virale Trends eine jüngere, engagierte Zielgruppe zu begeistern.'
                        },
                    },
                ],
            },
            {
                name: 'pay-1',
                skipInProgress: true,
                title: 'Anfragen',
                type: 'single-select',
                headline: 'Glückwunsch $name, Dein Angebot ist erstellt! 🥳🎉<br class="d-none d-lg-block">Wie planst du die Abrechnung nach einem erfolgreichem Beratungsgespräch?',
                headline2: 'Glückwunsch $name, Dein Angebot ist erstellt! 🥳🎉<br class="d-none d-lg-block">Wie planst du die Abrechnung nach einem erfolgreichem Beratungsgespräch?',
                subLine: 'Deine Anfrage ist völlig unverbindlich und führ zu keinem sofortigen Kauf oder Vertragsabschluss.',
                subLine2: 'Deine Anfrage ist völlig unverbindlich und führ zu keinem sofortigen Kauf oder Vertragsabschluss.',
                subBar: {
                    type: 'print',
                    first: '0% Finanzierung',
                    second: 'Keine Zinsen',
                    third: 'Flexible Ratenoptionen',
                },
                icon: require('@/assets/imgs/slidebar/inactive/Anfragen-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Anfragen-neu.svg'),
                noSkip: true,
                cards: [
                    {
                        forceSkip: true,
                        goto: 'pay-2',
                        title: 'Ich bevorzuge Ratenzahlung',
                        icon: require('@/assets/imgs/steps/pay-1/Ratenzahlung.svg'),
                    },
                    {
                        forceSkip: true,
                        goto: 'pay',
                        title: 'Ich bevorzuge Einmalzahlung',
                        icon: require('@/assets/imgs/steps/pay-1/Einmalzahlung.svg'),
                    },
                ]
            },
            {
                name: 'pay-2',
                skipInProgress: true,
                title: 'Zahloptionen',
                type: 'single-select',
                headline: 'Perfekt! 👍 Sagt dir das Angebot nach dem Beratungsgespräch zu, <br class="d-none d-lg-block">bieten wir dir folgende Optionen der <span style="color: var(--primary)">Ratenzahlung</span> an:',
                headline2: 'Jetzt bezahlen',
                subLine: 'Bitte beachte, dass Du bei dieser Option von unserem Finanzierungspartner Billie auf die nötige Bonität geprüft wirst.',
                subLine2: 'Bitte zahlen',
                subBar: {
                    type: 'print',
                    first: '0% Finanzierung',
                    second: 'Keine Zinsen',
                    third: 'Flexible Ratenoptionen',
                },
                noSkip: true,
                icon: require('@/assets/imgs/slidebar/inactive/Logo-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Logo-neu.svg'),
                cards: [
                    {
                        forceSkip: true,
                        goto: 'pay',
                        title: '$percent25 Anzahl.',
                        subText: 'Rest in <b>einer Rate</b><br> á <b>$price_rate1</b>',
                        info: {
                            icons: {calc: true, picture: false}
                        },
                        details: {
                            type: 'calc',
                            title: '<span style="font-weight: normal">Rechenbeispiel 1: (eine Rate)</span> <br class="d-none d-lg-block"> <span">Ratensumme inkl. MwSt.: <span style="color: var(--primary)">$total</span></span>',
                            img: require('@/assets/imgs/steps/logo/info/Logo-neu.jpg'),
                            pro: [
                                {
                                    text: '• <span style="font-weight: bold">$date</span> Beratungsgespräch mit Abschluss'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date2</span> Rechnung über <span style="font-weight: bold; color: var(--primary)">$rate</span> (25% Anzahlung von: <span style="font-weight: bold;">$total</span>) <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 14 Tagen (spätestens aber bis <span style="font-weight: bold">$date3</span>).'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date4</span> Fertigstellung Deiner bestellten Leistungen'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date5</span> Rechnung über die Restzahlung von: <span style="font-weight: bold; color: var(--primary)">$sum</span> <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 30 Tagen (spätestens aber bis <span style="font-weight: bold">$date6</span>).'
                                }
                            ],
                            technicalDetails: 'Solltest Du hierzu Fragen haben, zögere bitte nicht uns unter <b>0800 000 2681</b> anzurufen und es dir noch genauer erklären zu lassen.'
                        },
                        icon: require('@/assets/imgs/steps/pay-2/Rate-1.svg'),
                    },
                    {
                        forceSkip: true,
                        goto: 'pay',
                        title: '$percent25 Anzahl.',
                        subText: 'Rest in <b>2 Monatsraten</b><br> á <b>$price_rate2</b>',
                        info: {
                            icons: {calc: true, picture: false}
                        },
                        details: {
                            type: 'calc',
                            title: '<span style="font-weight: normal">Rechenbeispiel 2: (zwei Raten)</span> <br class="d-none d-lg-block"> <span">Ratensumme inkl. MwSt.: <span style="color: var(--primary)">$total</span></span>',
                            img: require('@/assets/imgs/steps/logo/info/Logo-neu.jpg'),
                            pro: [
                                {
                                    text: '• <span style="font-weight: bold">$date</span> Beratungsgespräch mit Abschluss'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date2</span> Rechnung über <span style="font-weight: bold; color: var(--primary)">$rate</span> (25% Anzahlung von: <span style="font-weight: bold;">$total</span>) <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 14 Tagen (spätestens aber bis <span style="font-weight: bold">$date3</span>).'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date4</span> Fertigstellung Deiner bestellten Leistungen'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date5</span> Rechnung über die Restzahlung von: <span style="font-weight: bold; color: var(--primary)">$sum</span> <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 60 Tagen (spätestens aber bis <span style="font-weight: bold">$date7</span>). <br class="d-none d-lg-block"> z.B. in 2 Raten von je <span style="font-weight: bold; color: var(--primary)">$sum2</span> <br class="d-none d-lg-block">1. Rate = <span style="font-weight: bold">$date8</span> <br class="d-none d-lg-block">2. Rate = <span style="font-weight: bold">$date9</span>'
                                }
                            ],
                            technicalDetails: 'Solltest Du hierzu Fragen haben, zögere bitte nicht uns unter <b>0800 000 2681</b> anzurufen und es dir noch genauer erklären zu lassen.'
                        },
                        icon: require('@/assets/imgs/steps/pay-2/Rate-2.svg'),
                    },
                    {
                        forceSkip: true,
                        goto: 'pay',
                        title: '$percent25 Anzahl.',
                        subText: 'Rest in <b>3 Monatsraten</b> <br> á <b>$price_rate3</b>',
                        info: {
                            icons: {calc: true, picture: false}
                        },
                        details: {
                            type: 'calc',
                            title: '<span style="font-weight: normal">Rechenbeispiel 3: (drei Raten)</span> <br class="d-none d-lg-block"> <span">Ratensumme inkl. MwSt.: <span style="color: var(--primary)">$total</span></span>',
                            img: require('@/assets/imgs/steps/logo/info/Logo-neu.jpg'),
                            pro: [
                                {
                                    text: '• <span style="font-weight: bold">$date</span> Beratungsgespräch mit Abschluss'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date2</span> Rechnung über <span style="font-weight: bold; color: var(--primary)">$rate</span> (25% Anzahlung von: <span style="font-weight: bold;">$total</span>) <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 14 Tagen (spätestens aber bis <span style="font-weight: bold">$date3</span>).'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date4</span> Fertigstellung Deiner bestellten Leistungen'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date5</span> Rechnung über die Restzahlung von: <span style="font-weight: bold; color: var(--primary)">$sum</span> <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 90 Tagen (spätestens aber bis <span style="font-weight: bold">$date10</span>). <br class="d-none d-lg-block"> z.B. in 3 Raten von je <span style="font-weight: bold; color: var(--primary)">$sum3</span> <br class="d-none d-lg-block">1. Rate = <span style="font-weight: bold">$date8</span> <br class="d-none d-lg-block">2. Rate = <span style="font-weight: bold">$date9</span><br class="d-none d-lg-block">3. Rate = <span style="font-weight: bold">$date11</span>'
                                }
                            ],
                            technicalDetails: 'Solltest Du hierzu Fragen haben, zögere bitte nicht uns unter <b>0800 000 2681</b> anzurufen und es dir noch genauer erklären zu lassen.'
                        },
                        icon: require('@/assets/imgs/steps/pay-2/Rate-3.svg'),
                    },
                    {
                        forceSkip: true,
                        goto: 'pay',
                        title: '$percent25 Anzahl.',
                        subText: 'Rest in <b>4 Monatsraten</b> <br> á <b>$price_rate4</b>',
                        info: {
                            icons: {calc: true, picture: false}
                        },
                        details: {
                            type: 'calc',
                            title: '<span style="font-weight: normal">Rechenbeispiel 4: (vier Raten)</span> <br class="d-none d-lg-block"> <span">Ratensumme inkl. MwSt.: <span style="color: var(--primary)">$total</span></span>',
                            img: require('@/assets/imgs/steps/logo/info/Logo-neu.jpg'),
                            pro: [
                                {
                                    text: '• <span style="font-weight: bold">$date</span> Beratungsgespräch mit Abschluss'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date2</span> Rechnung über <span style="font-weight: bold; color: var(--primary)">$rate</span> (25% Anzahlung von: <span style="font-weight: bold;">$total</span>) <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 14 Tagen (spätestens aber bis <span style="font-weight: bold">$date3</span>).'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date4</span> Fertigstellung Deiner bestellten Leistungen'
                                },
                                {
                                    text: '• <span style="font-weight: bold">$date5</span> Rechnung über die Restzahlung von: <span style="font-weight: bold; color: var(--primary)">$sum</span> <br class="d-none d-lg-block">Zahle diese bequem innerhalb von 120 Tagen (spätestens aber bis <span style="font-weight: bold">$date12</span>). <br class="d-none d-lg-block"> z.B. in 4 Raten von je <span style="font-weight: bold; color: var(--primary)">$sum4</span> <br class="d-none d-lg-block">1. Rate = <span style="font-weight: bold">$date8</span> <br class="d-none d-lg-block">2. Rate = <span style="font-weight: bold">$date9</span><br class="d-none d-lg-block">3. Rate = <span style="font-weight: bold">$date11</span><br class="d-none d-lg-block">4. Rate = <span style="font-weight: bold">$date13</span>'
                                }
                            ],
                            technicalDetails: 'Solltest Du hierzu Fragen haben, zögere bitte nicht uns unter <b>0800 000 2681</b> anzurufen und es dir noch genauer erklären zu lassen.'
                        },
                        icon: require('@/assets/imgs/steps/pay-2/Rate-4.svg'),
                    },
                ]
            },
            {
                name: 'pay',
                title: 'Anfragen',
                type: 'pay',
                noSkip: true,
                icon: require('@/assets/imgs/slidebar/inactive/Anfragen-neu.svg'),
                iconActive: require('@/assets/imgs/slidebar/active/Anfragen-neu.svg'),
                headline: 'Bei wem genau dürfen wir uns für das <br class="d-none d-lg-block"> <span style="color: var(--secondary)">innovative Beratungsgespräch</span> melden? 📞',
                headline2: 'Jetzt bezahlen',
                subLine: 'Wir können es kaum erwarten, mit Dir eine unvergleichliche Marke zu kreieren, <br class="d-none d-lg-block"> die Dich zum Lokal-Hero erhebt.',
                subLine2: 'Bitte zahlen',
            }
        ]
    },
    getters: {
        getCurrentStepIndex(state: any) {
            let currentStep = new Step(state.steps.find((step: any) => step.name === state.currentStep));

            if (currentStep.isUndefined()) {
                for (const step of state.steps) {
                    for (const child of step.childs || []) {
                        if (child.name === state.currentStep) {
                            currentStep = new Step(child);
                        }
                    }
                }
            }


            let index = state.steps.findIndex((step: any) => step.name === currentStep.getName());

            if (index === -1) {
                let i = 0;
                for (const step of state.steps) {
                    for (const child of step.childs || []) {
                        if (child.name === currentStep.getName()) {
                            index = i;
                        }
                    }
                    i++;
                }
            }

            return index;
        },
        getFullName(state: any) {
            return state.name;
        },
        getCurrentStep(state: any) {
            const step = new Step(state.steps.find((step: any) => step.name === state.currentStep));
            if (step.isUndefined()) {
                for (const step of state.steps) {
                    if (step.childs) {
                        for (const child of step.childs) {
                            if (child.name === state.currentStep) {
                                return new Step(child);
                            }
                        }
                    }
                }
            }

            return step;
        },
        getCurrentStepProgress(state: any) {
            const step = new Step(state.steps.find((step: any) => step.name === state.currentStep));

            if (step.isUndefined()) {
                for (const step of state.steps) {
                    if (step.childs) {
                        for (const child of step.childs) {
                            if (child.name === state.currentStep) {
                                return new Step(step);
                            }
                        }
                    }
                }
            }

            return step;
        },
        getNextStep(state: any) {
            let currentStep = new Step(state.steps.find((step: any) => step.name === state.currentStep));

            if (currentStep.isUndefined()) {
                for (const step of state.steps) {
                    if (step.childs) {
                        for (const child of step.childs) {
                            if (child.name === state.currentStep) {
                                currentStep = new Step(step);
                            }
                        }
                    }
                }
            }

            let next = false;
            for (const step of state.steps) {
                if (step.name === currentStep.getName()) {
                    next = true;
                    continue;
                }

                if (next) {
                    return new Step(step);
                }
            }
        },
        getStepsLength(state: any) {
            return state.steps.length;
        },
        getPrice(state: any) {
            let price = 0;

            for (const step of state.steps) {
                if (!step.cards) {
                    continue;
                }

                for (const card of step.cards) {
                    if (card.price && card.selected) {
                        let myPrice = card.price;
                        if (card.specs && card.specs.edited && card.specs.price) {
                            myPrice = card.specs.price;
                        }

                        price += myPrice;
                    }
                }

                if (step.childs) {
                    for (const cStep of step.childs) {
                        if (!cStep.cards) {
                            continue;
                        }

                        for (const card of cStep.cards) {
                            if (card.price && card.selected) {
                                let myPrice = card.price;
                                if (card.specs && card.specs.edited && card.specs.price) {
                                    myPrice = card.specs.price;
                                }

                                price += myPrice;
                            }
                        }
                    }
                }
            }


            return price;
        },
        getSteps(state: any) {
            const steps: any = [];


            for (const step of state.steps) {
                if (!step.cards) {
                    continue;
                }

                for (const card of step.cards) {
                    if (card.selected) {
                        if (!steps.find((sStep: any) => sStep.name === step.name)) {
                            steps.push(step);
                            break;
                        }
                    }
                }

                if (step.childs) {
                    for (const cStep of step.childs) {
                        if (!cStep.cards) {
                            continue;
                        }

                        for (const card of cStep.cards) {
                            if (card.selected) {
                                card['left'] = 'yes';
                                if (!steps.find((sStep: any) => sStep.name === step.name)) {
                                    steps.push(cStep);
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            return steps;
        },
        getItems(state: any) {
            const items = [];

            for (const step of state.steps) {
                if (!step.cards) {
                    continue;
                }

                for (const card of step.cards) {
                    if (card.price && card.selected && card.price && card.price > 0) {
                        items.push(card);
                    }
                }

                if (step.childs) {
                    for (const cStep of step.childs) {
                        if (!cStep.cards) {
                            continue;
                        }

                        for (const card of cStep.cards) {
                            if (card.price && card.selected && card.price && card.price > 0) {
                                card['left'] = 'yes';
                                items.push(card);
                            }
                        }
                    }
                }
            }

            return items;
        },
        getPriceMonthly(state: any) {
            let price = 0;

            for (const step of state.steps) {
                for (const card of step.cards || []) {
                    if (card.selected && card.price && card.priceKind === 'monthly') {
                        price += card.price;
                    }
                }

                for (const child of step.childs || []) {
                    for (const card of child.cards || []) {
                        if (card.selected && card.price && card.priceKind === 'monthly') {
                            price += card.price;
                        }
                    }
                }
            }

            return price;
        }
    },
    mutations: {
        setCartOpened(state: any, payload: any) {
            state.cartOpened = payload;
        },
        setAV(state: any, payload: any) {
            state.av = payload;
        },
        setCurrentSpecificClicked(state: any, payload: any) {
            state.currentSpecificClicked = payload;
        },
        setLoading(state: any, payload: any): void {
            state.loading = payload;
            state.hasEverLoaded = true;
        },
        setName(state: any, payload: any): void {
            state.name = payload;
        },
        setSpecific(state: any, payload: any): void {
            state.specific = payload;
        },
        setSpecCard(state: any, payload: any): void {
            state.specCard = payload;
        },
        next(state: any): void {
            let currentStep = new Step(state.steps.find((step: any) => step.name === state.currentStep));
            if (currentStep.isUndefined()) {
                for (const step of state.steps) {
                    for (const child of step.childs || []) {
                        if (child.name === state.currentStep) {
                            currentStep = new Step(child);
                        }
                    }
                }
            }

            if (currentStep.hasGoTo()) {
                const goto = currentStep.getRaw()['goto'];
                if (goto) {
                    state.visited.push(state.currentStep);
                    state.currentStep = goto;
                    state.visited2.push(state.currentStep);
                    window.scrollTo(0, 0);
                    return;
                }
            }

            if (currentStep.getType() === 'single-select') {
                for (const card of currentStep.getCards()) {
                    if (card.selected && card.goto !== undefined) {
                        state.visited.push(state.currentStep);
                        state.currentStep = card.goto;
                        state.visited2.push(state.currentStep);
                        window.scrollTo(0, 0);
                        return;
                    }
                }
            }

            let index = state.steps.findIndex((step: any) => step.name === currentStep.getName());

            if (index === -1) {
                let i = 0;
                for (const step of state.steps) {
                    for (const child of step.childs || []) {
                        if (child.name === currentStep.getName()) {
                            index = i;
                        }
                    }
                    i++;
                }
            }

            state.visited.push(state.currentStep);
            state.currentStep = new Step(state.steps[index + 1]).getName();
            state.visited2.push(state.currentStep);

            if (state.currentStep === 'pay-1') {
                state.loading = true;
            }


            window.scrollTo(0, 0);
        },
        previous(state: any) {
            state.currentStep = state.visited[state.visited.length - 1];
            state.visited.splice(state.visited.length - 1, 1);
            window.scrollTo(0, 0);

            if (!state.currentStep || typeof state.currentStep !== 'object' || state.currentStep.name !== 'pay') {
                document.body.classList.remove("badge");
            }
        },
        goto(state: any, payload: any) {
            state.currentStep = payload;
        }
    },
    actions: {},
};

export default createStore(store);
