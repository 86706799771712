


import PrintTheme from "@/layout/popup/info/logo/print-theme.vue";
import LogoTheme from "@/layout/popup/info/logo/logo-theme.vue";
import CalcTheme from "@/layout/popup/info/logo/calc-theme.vue";

export default {
  components: {CalcTheme, LogoTheme, PrintTheme},
  props: ['info']
}
