
import {Options, Vue} from 'vue-class-component';
import {LottieAnimation} from "lottie-web-vue";
import {mapMutations, mapState} from "vuex";

@Options({
  name: 'flow-concept',
  components: {LottieAnimation},
  computed: {
    ...mapState(['loading'])
  },
  data() {
    return {
      points: '.',
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    animationPoints() {
      this.points = '.';
      setTimeout(() => {
        this.points = '..';
        setTimeout(() => {
          this.points = '...';
          setTimeout(() => {
            this.animationPoints();
          }, 400);
        }, 400);
      }, 400);
    }
  },
  mounted() {
    this.setLoading(true);
    this.animationPoints();
    setTimeout(() => {
     this.setLoading(false);
    }, 3000);
  }
})
export default class Concept extends Vue {

}
