<template>
  <div class="ui-button">
    <button :style="{borderRadius: `${border || 8}px`}"
            :class="{'secondary' : this.secondary !== undefined, 'mobile': isMobile, 'third' : this.third !== undefined, 'disabled' : this.disabled === true, mcbrands: variety === 'mcbrands', mcbrands2: variety === 'mcbrands2', mcbrands3: variety === 'mcbrands3',  mcbrands4: variety === 'mcbrands4', mcbrands5: variety === 'mcbrands5', skip: variety === 'skip',  'skip-next': variety === 'skip-next',  'skip-next-active': variety === 'skip-next-active'}">
      <img v-if="iconleft !== undefined" :src="iconleft" alt="">
      <slot>
      </slot>
      <img v-if="iconright !== undefined" :src="iconright" alt="">
      <i v-if="icon !== undefined && icon !== ''" :class="icon"/>
    </button>
  </div>
</template>
<script>
export default {
  name: "ui-button",
  props: ['secondary', 'third', 'disabled', 'icon', 'border', 'variety', 'iconleft', 'iconright'],
}
</script>
<style lang="scss" scoped>
div.ui-button {
  width: 100%;

  button {
    width: 100%;
    background: #292929;
    color: rgb(255, 255, 255);
    height: 48px;
    outline: none;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 1.5rem;
    text-align: start;
    gap: 2rem;
    font-weight: 600;
    cursor: hand;
    box-shadow: 0 0 4px .5px rgba(0, 0, 0, 0.19);
    transition: .2s;
    position: relative;
    line-height: 1.25rem;

    button:active {
      outline: none;
      border: none;
    }

    button:focus {
      outline:0;
    }

    img {
      height: 16px;
    }

    i {
      font-size: 24px;
    }

    &:hover {
      background: rgba(41, 41, 41, 0.95);
      transform: translateY(-4px);
    }

    &.mobile{
      &:hover{
        transform: unset !important;
      }
    }

    &.mcbrands {
      background: var(--yellow);
      color: #292929;
      padding: 1rem;
      height: 60px;
      font-size: 17px;
      width: 320px;
      display: flex;
      justify-content: center;
      gap: 10px;

      &:hover {
        background: #FFD14FFF;
        transform: translateY(-4px);
      }

      i {
      }
    }

    &.mcbrands3 {
      background: var(--yellow);
      color: #292929;
    }

    &.mcbrands5 {
      background: var(--yellow);
      color: #292929;
      padding: 1.6rem 1.5rem !important;
    }

    &.mcbrands4 {
      background: var(--primary);
      color: white;
      padding: 1.7rem;
      white-space: nowrap;
    }

    &.skip {
      background: #d6d6d6;
      color: #292929;
      white-space: nowrap;
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 15px;
    }

    &.skip-next {
      width: 160px;
      background: #d6d6d6;
      color: #292929;
      white-space: nowrap;
      gap: 2rem;
      padding: 0 2rem;
      padding-right: 1rem;
      font-size: 15px;
    }

    &.skip-next-active {
      width: 160px;
      background: var(--yellow);
      color: #292929;
      white-space: nowrap;
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 15px;
    }

    &.mcbrands2 {
      padding: 0;
      width: 300px;
      display: flex;
      height: 60px;
      font-size: 17px;
      justify-content: center;
      gap: 10px;


      i {
      }
    }

    &.disabled {
      background: rgba(138, 141, 147, .1);
      color: var(--bright-grey);
      cursor: not-allowed;

      &:hover {
        background: rgba(138, 141, 147, 0.2);
        transform: unset;
      }

    }

    &.secondary {
      background: #f1f1f1;
      color: var(--secondary);


      &:hover {
        background: rgba(138, 141, 147, 0.3);
      }

      i {
        margin-left: unset;
      }
    }

    &.third {
      justify-content: space-between;
      padding: 0 .8rem !important;
    }
  }
}
</style>
