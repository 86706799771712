
import {Options, Vue} from 'vue-class-component';
import UiRadio from "@/components/ui-radio.vue";
import UiButton from "@/components/ui-button.vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import InfoPopup from "@/layout/popup/info-popup.vue";
import UiPopup from "@/components/ui-popup.vue";

@Options({
  name: 'letterhead-card',
  components: {UiPopup, InfoPopup, UiButton, UiRadio},
  computed: {
    ...mapState(['specific']),
    ...mapGetters(['getCurrentStep']),
  },
  data() {
    return {
      infoPopupState: false,
      infoPopupData: null,
    }
  },
 methods: {
    ...mapMutations(['setCurrentSpecificClicked']),
    resetInfo() {
      this.infoPopupState = false;
      this.infoPopupData = null;
    },
    clickInfo() {
      for (const card of this.getCurrentStep.getCards()) {
        if (card.name === this.specific['name']) {
          this.setCurrentSpecificClicked(card || {});
          this.$emit('infoClicked')
          break;
        }
      }
    },
    done() {
      let peaces = 0;
      for (const value of Object.values(this.specific.specifics.attr.peaces) as any) {
        if (value.active) {
          peaces = value.peace;
          break;
        }
      }

      const obj = {
        price: this.calcPrice(),
        peaces,
        attr: this.specific.specifics.attr
      }
      this.$emit('done', obj);
    },
    changePeaces(peaces: any) {
      switch (peaces) {
        case 'first':
          this.specific.specifics.attr.peaces.second.active = false;
          this.specific.specifics.attr.peaces.third.active = false;
          this.specific.specifics.attr.peaces.first.active = true;
          break;
        case 'second':
          this.specific.specifics.attr.peaces.first.active = false;
          this.specific.specifics.attr.peaces.third.active = false;
          this.specific.specifics.attr.peaces.second.active = true;
          break;
        case 'third':
          this.specific.specifics.attr.peaces.first.active = false;
          this.specific.specifics.attr.peaces.second.active = false;
          this.specific.specifics.attr.peaces.third.active = true;
          break;
      }
    },
    calcPrice() {
      let price = 0;
      for (const value of Object.values(this.specific.specifics.attr.peaces) as any) {
        if (value.active) {
          price = value.price;
        }
      }

      return price;
    }
  }
})
export default class LetterheadCard extends Vue {

}
