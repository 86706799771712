export default {
    namespaced: true,
    state: () => ({
        specifics: [
            {
                name: 'visitenkarte',
                specifics: {
                    type: 'visitenkarte',
                    attr: {
                        roundedCorner: true,
                        defaultCorner: false,
                        typeGlaenzend: false,
                        typeMatt: false,
                        typeBeratung: true,
                        peaces: {
                            first: {
                                peace: 250,
                                price: 79,
                                active: true,
                            },
                            second: {
                                peace: 500,
                                price: 83,
                                active: false,
                            },
                            third: {
                                peace: 1000,
                                price: 98,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'flyer',
                specifics: {
                    type: 'flyer',
                    attr: {
                        typeGlaenzend: false,
                        typeMatt: false,
                        typeBeratung: true,
                        peaces: {
                            first: {
                                peace: 500,
                                price: 129,
                                active: true,
                            },
                            second: {
                                peace: 1000,
                                price: 139,
                                active: false,
                            },
                            third: {
                                peace: 2500,
                                price: 170,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'letterhead',
                specifics: {
                    type: 'letterhead',
                    attr: {
                        peaces: {
                            first: {
                                peace: 500,
                                price: 145,
                                active: true,
                            },
                            second: {
                                peace: 1000,
                                price: 159,
                                active: false,
                            },
                            third: {
                                peace: 2500,
                                price: 199,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'pen',
                specifics: {
                    type: 'pen',
                    attr: {
                        colors: {
                            first: {
                                name: 'Blau',
                                value: 'blue',
                                active: true,
                            },
                            second: {
                                name: 'Schwarz',
                                value: 'black',
                                active: false,
                            },
                            third: {
                                name: 'Rot',
                                value: 'red',
                                active: false,
                            },
                            fourth: {
                                name: 'Gelb',
                                value: 'red',
                                active: false,
                            },
                            fifth: {
                                name: 'Weiß',
                                value: 'red',
                                active: false,
                            }
                        },
                        peaces: {
                            first: {
                                peace: 100,
                                price: 149,
                                active: true,
                            },
                            second: {
                                peace: 500,
                                price: 381,
                                active: false,
                            },
                            third: {
                                peace: 1000,
                                price: 589,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'stamp',
                specifics: {
                    type: 'stamp',
                    attr: {
                        colors: {
                            first: {
                                name: 'Blau',
                                value: 'blue',
                                active: true,
                            },
                            second: {
                                name: 'Schwarz',
                                value: 'black',
                                active: false,
                            },
                            third: {
                                name: 'Rot',
                                value: 'red',
                                active: false,
                            },
                            fourth: {
                                name: 'Grün',
                                value: 'green',
                                active: false,
                            },
                        },
                        peaces: {
                            first: {
                                peace: 1,
                                price: 60,
                                active: true,
                            },
                            second: {
                                peace: 2,
                                price: 85,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 110,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'rollup',
                specifics: {
                    type: 'rollup',
                    attr: {
                        peaces: {
                            first: {
                                peace: 1,
                                price: 95,
                                active: true,
                            },
                            second: {
                                peace: 2,
                                price: 138,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 183,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'keys',
                specifics: {
                    type: 'keys',
                    attr: {
                        peaces: {
                            first: {
                                peace: 100,
                                price: 209,
                                active: true,
                            },
                            second: {
                                peace: 250,
                                price: 389,
                                active: false,
                            },
                            third: {
                                peace: 500,
                                price: 519,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'companysign',
                specifics: {
                    type: 'companysign',
                    attr: {
                        peaces: {
                            first: {
                                name: 'L',
                                price: 95,
                                active: true,
                            },
                            second: {
                                name: 'XL',
                                price: 107,
                                active: false,
                            },
                            third: {
                                name: 'XXL',
                                price: 145,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'giftcard',
                specifics: {
                    type: 'giftcard',
                    attr: {
                        peaces: {
                            first: {
                                peace: 250,
                                price: 119,
                                active: true,
                            },
                            second: {
                                peace: 500,
                                price: 139,
                                active: false,
                            },
                            third: {
                                peace: 1000,
                                price: 179,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'coffeecup',
                specifics: {
                    type: 'coffeecup',
                    attr: {
                        peaces: {
                            first: {
                                peace: 6,
                                price: 95,
                                active: true,
                            },
                            second: {
                                peace: 12,
                                price: 126,
                                active: false,
                            },
                            third: {
                                peace: 36,
                                price: 265,
                                active: false,
                            }
                        }
                    }
                },
            }, {
                name: 'beachflag',
                specifics: {
                    type: 'beachflag',
                    attr: {
                        sizes: {
                            first: {
                                name: 'S',
                                id: 's',
                                prices: {
                                    first: 139,
                                    second: 219,
                                    third: 295,
                                },
                                active: true,
                            },
                            second: {
                                name: 'M',
                                id: 'm',
                                prices: {
                                    first: 159,
                                    second: 255,
                                    third: 350,
                                },
                                active: false,
                            },
                            third: {
                                name: 'L',
                                id: 'l',
                                prices: {
                                    first: 175,
                                    second: 285,
                                    third: 395,
                                },
                                active: false,
                            }
                        },
                        peaces: {
                            first: {
                                peace: 1,
                                price: 0,
                                active: true,
                            },
                            second: {
                                peace: 2,
                                price: 0,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 0,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'window',
                specifics: {
                    type: 'window',
                    attr: {
                        sizes: {
                            first: {
                                name: 'S',
                                id: 's',
                                prices: {
                                    first: 75,
                                    second: 89,
                                    third: 99,
                                },
                                active: true,
                            },
                            second: {
                                name: 'M',
                                id: 'm',
                                prices: {
                                    first: 75,
                                    second: 89,
                                    third: 99,
                                },
                                active: false,
                            },
                            third: {
                                name: 'L',
                                id: 'l',
                                prices: {
                                    first: 85,
                                    second: 105,
                                    third: 130,
                                },
                                active: false,
                            }
                        },
                        peaces: {
                            first: {
                                peace: 1,
                                price: 0,
                                active: true,
                            },
                            second: {
                                peace: 2,
                                price: 0,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 0,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'bag',
                specifics: {
                    type: 'bag',
                    attr: {
                        sizes: {
                            first: {
                                name: 'S',
                                id: 's',
                                prices: {
                                    first: 335,
                                    second: 450,
                                    third: 720,
                                },
                                active: true,
                            },
                            second: {
                                name: 'M',
                                id: 'm',
                                prices: {
                                    first: 351,
                                    second: 481,
                                    third: 779,
                                },
                                active: false,
                            },
                        },
                        peaces: {
                            first: {
                                peace: 250,
                                price: 0,
                                active: true,
                            },
                            second: {
                                peace: 500,
                                price: 0,
                                active: false,
                            },
                            third: {
                                peace: 1000,
                                price: 0,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'birthdaycard',
                specifics: {
                    type: 'birthdaycard',
                    attr: {
                        peaces: {
                            first: {
                                peace: 250,
                                price: 119,
                                active: true,
                            },
                            second: {
                                peace: 500,
                                price: 139,
                                active: false,
                            },
                            third: {
                                peace: 1000,
                                price: 179,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'pencilselling',
                specifics: {
                    type: 'pencilselling',
                    attr: {
                        peaces: {
                            first: {
                                peace: 100,
                                price: 199,
                                active: true,
                            },
                            second: {
                                peace: 250,
                                price: 344,
                                active: false,
                            },
                            third: {
                                peace: 500,
                                price: 548,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'contract',
                specifics: {
                    type: 'contract',
                    attr: {
                        typeOhneFuellhoehe: false,
                        typeFuenfMMFuellhoehe: false,
                        typeBeratung: true,
                        peaces: {
                            first: {
                                peace: 100,
                                price: 199,
                                active: true,
                            },
                            second: {
                                peace: 250,
                                price: 233,
                                active: false,
                            },
                            third: {
                                peace: 500,
                                price: 295,
                                active: false,
                            }
                        }
                    }
                },
            },
            {
                name: 'achievement',
                specifics: {
                    type: 'achievement',
                    attr: {
                        peaces: {
                            first: {
                                peace: 1,
                                price: 75,
                                active: true,
                            },
                            second: {
                                peace: 2,
                                price: 79,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 84,
                                active: false,
                            }
                        },
                        snapFrame: {
                            first: {
                                peace: 1,
                                price: 29,
                                active: false,
                            },
                            second: {
                                peace: 2,
                                price: 51,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 79,
                                active: false,
                            },
                            fourth: {
                                peace: 0,
                                price: 0,
                                active: true,
                            }
                        }
                    }
                },
            },
            {
                name: 'pavement',
                specifics: {
                    type: 'pavement',
                    attr: {
                        peaces: {
                            first: {
                                peace: 2,
                                price: 99,
                                active: true,
                            },
                            second: {
                                peace: 4,
                                price: 119,
                                active: false,
                            },
                            third: {
                                peace: 6,
                                price: 135,
                                active: false,
                            }
                        },
                        pavements: {
                            first: {
                                peace: 1,
                                price: 90,
                                active: false,
                            },
                            second: {
                                peace: 2,
                                price: 175,
                                active: false,
                            },
                            third: {
                                peace: 3,
                                price: 258,
                                active: false,
                            },
                            fourth: {
                                peace: 0,
                                price: 0,
                                active: true,
                            }
                        }
                    }
                },
            }
        ]
    }),
    mutations: {},
    actions: {},
    getters: {}
}
