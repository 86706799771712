import {createMemoryHistory, createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Welcome from "@/view/welcome/welcome.vue";
import Flow from "@/view/flow/flow.vue";
import Name from "@/view/name/name.vue";
import done from "@/view/done/done.vue";
import Brand from "@/view/brand/brand.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'welcome',
        redirect: () => {
            return {
                path: '/welcome',
            }
        },
        children: [
            {
                path: 'flow/:meta',
                component: Flow,
                props: true,
            },
            {
                path: 'name',
                component: Name
            },
            {
                path: 'welcome',
                component: Welcome
            },
            {
                path: 'brand',
                component: Brand
            },
            {
                path: 'done',
                component: done
            },
        ]
    },
]

const router = createRouter({
    history: createMemoryHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    },
    routes
})

router.beforeEach((to, from) => {
    if(to.path.includes("/flow") || to.path.includes("/name")){
        document.body.classList.add("flow");
    } else {
        document.body.classList.remove("flow");
    }
})

export default router
