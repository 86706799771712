
import {Options, Vue} from 'vue-class-component';
import ImagePreview from "@/layout/popup/info/image-preview.vue";
import {DateAgent} from "@/@core/agent/date-agent";
import {mapGetters} from "vuex";

@Options({
  name: 'print-theme',
  components: {ImagePreview},
  computed: {
    ...mapGetters(['getPrice']),
  },
  props: ['info'],
  methods: {
    replaceVariables(input: string){
      const vDate13 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 127);
      input = input.replaceAll('$date13', new DateAgent(vDate13).getFormattedDate());

      const vDate12 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 153);
      input = input.replaceAll('$date12', new DateAgent(vDate12).getFormattedDate());

      const vDate11 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 96);
      input = input.replaceAll('$date11', new DateAgent(vDate11).getFormattedDate());

      const vDate10 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 123);
      input = input.replaceAll('$date10', new DateAgent(vDate10).getFormattedDate());

      const vDate9 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 65);
      input = input.replaceAll('$date9', new DateAgent(vDate9).getFormattedDate());

      const vDate8 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 34);
      input = input.replaceAll('$date8', new DateAgent(vDate8).getFormattedDate());

      const vDate7 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 93);
      input = input.replaceAll('$date7', new DateAgent(vDate7).getFormattedDate());

      const vDate6 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 63);
      input = input.replaceAll('$date6', new DateAgent(vDate6).getFormattedDate());

      const vDate5 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 33);
      input = input.replaceAll('$date5', new DateAgent(vDate5).getFormattedDate());


      const vDate4 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 32);
      input = input.replaceAll('$date4', new DateAgent(vDate4).getFormattedDate());

      const vDate3 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 18);
      input = input.replaceAll('$date3', new DateAgent(vDate3).getFormattedDate());

      const vDate2 = new Date(Date.now() + 1000 * 60 * 60 * 24 * 4);
      input = input.replaceAll('$date2', new DateAgent(vDate2).getFormattedDate());

      const vDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 3);
      input = input.replaceAll('$date', new DateAgent(vDate).getFormattedDate());

      const brutto = this.getPrice + this.getPrice * 0.19;
      input = input.replace('$rate', `${this.formatMyPrice(brutto * 0.25)}`);

      input = input.replaceAll("$total", this.formatMyPrice(this.getPrice + this.getPrice * 0.19))

      input = input.replaceAll('$sum4', this.formatMyPrice((brutto - brutto * 0.25) / 4));
      input = input.replaceAll('$sum3', this.formatMyPrice((brutto - brutto * 0.25) / 3));
      input = input.replaceAll('$sum2', this.formatMyPrice((brutto - brutto * 0.25) / 2));
      input = input.replaceAll('$sum', this.formatMyPrice((brutto - brutto * 0.25)));

      return input;
    },
    formatMyPrice(priceNum: any, showFloor = true) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      });

      let forReturn = formatter.format(priceNum).replace(/\s/g, "");
      if (!showFloor) {
        forReturn = forReturn.split(",")[0];
      }

      return forReturn;
    },
  }
})
export default class PrintTheme extends Vue {
}
