<template>
  <div class="ui-scroll-instruction">
    <div class='mouse'>
      <span class='scroll-down'></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ui-scroll-instruction'
}
</script>
<style scoped lang="scss">
div.ui-scroll-instruction {
  width: 50px;

  @-webkit-keyframes scroll-inner {
    from {
      margin-top: 15%;
    }
    to {
      margin-top: 50%;
    }
  }
  @keyframes scroll-inner {
    from {
      margin-top: 15%;
    }
    to {
      margin-top: 50%;
    }
  }
  @-webkit-keyframes scroll-mouse {
    from {
      margin-top: 0;
    }
    to {
      margin-top: 15px;
    }
  }
  @keyframes scroll-mouse {
    from {
      margin-top: 0;
    }
    to {
      margin-top: 15px;
    }
  }

  div.mouse {
    width: 50px;
    height: 80px;
    border: solid 2px var(--border-grey);
    border-radius: 25px;
    -webkit-animation: scroll-mouse 1.5s;
    animation: scroll-mouse 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    span.scroll-down {
      display: block;
      width: 10px;
      height: 10px;
      background: var(--secondary);
      border-radius: 50%;
      margin: 15% auto auto auto;

      -webkit-animation: scroll-inner 1.5s;
      animation: scroll-inner 1.5s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      animation-timing-function: ease

    }
  }
}
</style>
