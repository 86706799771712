export class StepSubbar {

    constructor(
        private readonly subBar: any
    ) {
    }

    public getType() {
        return this.subBar['type'];
    }

    public getRaw() {
        return this.subBar;
    }


}