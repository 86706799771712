<template>
  <div class="ui-input2" style="position: relative">
    <div class="icon" v-if="icon !== undefined">
      <img :src="icon" alt="">
    </div>
    <div class="icon" style="cursor: pointer" v-if="type !== undefined && type === 'phone'">
      <vue3-popper>
        <div class="language d-flex align-items-center justify-content-center gap-1" style="height: 50px; width: 50px">
          <img src="@/assets/imgs/languages/germany.png" v-if="lang === 'de'" alt=""
               style="width: 22px; height: 22px">
          <img src="@/assets/imgs/languages/austria.png" v-if="lang === 'au'" alt=""
               style="width: 22px; height: 22px">
          <img src="@/assets/imgs/languages/switzerland.png" v-if="lang === 'sw'" alt=""
               style="width: 22px; height: 22px">
          <div class="arrow" style="font-size: 12px;">
            <i class="bi bi-caret-down-fill"></i>
          </div>
        </div>
        <template #content="{ close }">
          <div class="dropdown languages">
            <div class="language" v-for="language in languages" :key="language"
                 @click="selectLanguage(language); close()">
              <img :src="language.icon" alt="">
            </div>
          </div>
        </template>
      </vue3-popper>
    </div>
    <input @focusout="showPredict = false" @input="predict" :placeholder="placeholder"
           :class="{icon: icon !== undefined || (type !== undefined && type === 'phone')}" type="text"
           v-model="model">
    <vue3-popper :show="showPredict" v-if="predictIt" style="position: absolute; top: 38px; left: 212px">
      <div class="test" style="display: none">
        asdf
      </div>
      <template #content>
        <div class="dropdown">
          <div class="d-flex flex-column gap-1 predicts" v-if="!predictLoading">
            <div class="pred" @click="clickPredict(pred)" v-for="pred in predicts" :key="pred">
              <div class="left">
                <div class="street" v-html="getRoad(pred)">
                </div>
                <div class="city">
                  {{ pred.postcode }} {{ pred.town }}
                </div>
              </div>
              <div class="right">
                <div class="country" v-if="pred.country_code === 'de'">
                  Deutschland
                </div>
                <div class="country" v-if="pred.country_code === 'at'">
                  Österreich
                </div>
                <div class="country" v-if="pred.country_code === 'ch'">
                  Schweiz
                </div>
              </div>
            </div>
          </div>
          <div class="loading d-flex justify-content-center align-items-center" style="height: 60px"
               v-if="predictLoading">
            <div class="loadingio-spinner-spinner-3fn24un7fxj">
              <div class="ldio-ir7e7ibh1t">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vue3-popper>
  </div>
  <small style="color: #ff0000">{{error}}</small>
</template>
<script>
import {Method} from "@/@core/ajax/request/_schema/method.enum";

export default {
  name: 'ui-input2',
  props: ['placeholder', 'modelValue', 'icon', 'predictIt', 'type'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  data() {
    return {
      typingTimeout: null,
      showPredict: false,
      predictLoading: true,
      lang: 'de',
      error: null,
      predicts: [],
      languages: [
        {
          icon: require('@/assets/imgs/languages/germany.png'),
          code: 'de',
          phone: '+49',
        },
        {
          icon: require('@/assets/imgs/languages/austria.png'),
          code: 'au',
          phone: '+43'
        },
        {
          icon: require('@/assets/imgs/languages/switzerland.png'),
          code: 'sw',
          phone: '+41'
        }
      ],
    }
  },
  mounted() {
    if(this.type && this.type === 'phone'){
      this.selectLanguage(this.languages[0]);
    }
  },
  methods: {
    selectLanguage(language) {
      this.lang = language.code;
      this.model = this.changeCountryCode(this.model, language.phone);
    },
    changeCountryCode(phoneNumber, newCode) {
      const countryCodes = ['+41', '+49', '+43'];

      let currentCode = countryCodes.find(code => phoneNumber.startsWith(code));

      const landlineCodes = ['30', '40', '89'];
      const mobileCodes = ['15', '16', '17'];

      let cleanedNumber = phoneNumber.replace(currentCode, '').replace(/[^\d]/g, '');

      if (currentCode) {
        cleanedNumber = phoneNumber.replace(currentCode, '');
      } else if (phoneNumber.startsWith('0')) {
        cleanedNumber = phoneNumber.substring(1);
      }

      let formattedNumber = '';
      if (landlineCodes.includes(cleanedNumber.substring(0, 2))) {
        formattedNumber = cleanedNumber.substring(0, 2) + ' ' + cleanedNumber.substring(2);
      } else if (mobileCodes.includes(cleanedNumber.substring(0, 2))) {
        formattedNumber = cleanedNumber.substring(0, 3) + ' ' + cleanedNumber.substring(3);
      } else {
        formattedNumber = cleanedNumber;
      }

      if (newCode[newCode.length - 1] !== ' ' && formattedNumber[0] !== ' ') {
        newCode += ' ';
      }

      return newCode + formattedNumber;
    },
    invalidPipe(reason) {
      this.error = reason;
    },
    validPipe(reason) {
      this.error = null;
    },
    getRoad(pred) {
      const match = this.model.match(/\b\d+\w*\b/);
      return `${pred.road} ${match && match.length > 0 ? `<b style="font-size: 11px">${match[0]}</b>` : ''}`;
    },
    clickPredict(pred) {
      this.$emit('predict', pred);
    },
    async predict(el) {
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }

      this.typingTimeout = setTimeout(async () => {
        this.showPredict = true;
        this.predictLoading = true;

        const json = await this.$core.ajax?.fetch({
          url: 'pages/flow/steps/address/predictStreet',
          method: Method.GET,
          json: {
            value: el.srcElement.value,
          },
          auth: false,
        });


        this.predictLoading = false;
        this.predicts = json;

      }, 500);
    }
  }
}
</script>
<style scoped lang="scss">
div.ui-input2 {
  position: relative;

  div.dropdown {
    background: #f3f3f3;
    box-shadow: 0 0 4px .5px rgba(0, 0, 0, 0.19);
    padding: .5rem;
    width: 320px;
    max-height: 200px;
    overflow-y: auto;

    &.languages {
      width: 50px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: .8rem;

      div.language {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        transition: .2s;

        img {
          height: 26px;
          width: 26px;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    div.predicts {
      div.pred {
        border-radius: 3px;
        height: 40px;
        transition: .2s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .5rem;

        div.street {
          font-size: 13px;
        }

        div.city {
          font-size: 11px;
        }

        div.country {
          font-weight: bold;
          font-size: 12px;
        }


        &:hover {
          background: #dedede;
        }
      }
    }


    @keyframes ldio-ir7e7ibh1t {
      0% {
        opacity: 1
      }
      100% {
        opacity: 0
      }
    }

    .ldio-ir7e7ibh1t div {
      left: 47px;
      top: 24px;
      position: absolute;
      animation: ldio-ir7e7ibh1t linear 1s infinite;
      background: #7e7e7e;
      width: 6px;
      height: 12px;
      border-radius: 3px / 6px;
      transform-origin: 3px 26px;
    }

    .ldio-ir7e7ibh1t div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.9166666666666666s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.8333333333333334s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.75s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.6666666666666666s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.5833333333333334s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.5s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.4166666666666667s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.3333333333333333s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.25s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.16666666666666666s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.08333333333333333s;
      background: #7e7e7e;
    }

    .ldio-ir7e7ibh1t div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
      background: #7e7e7e;
    }

    .loadingio-spinner-spinner-3fn24un7fxj {
      width: 48px;
      height: 48px;
      display: inline-block;
      overflow: hidden;
    }

    .ldio-ir7e7ibh1t {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(0.48);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }

    .ldio-ir7e7ibh1t div {
      box-sizing: content-box;
    }
  }

  input {
    padding: 1rem;
    width: 100%;
    outline: none;
    border: 2px solid #e9e9ea;
    border-radius: 6px;
    height: 50px;

    &.icon {
      padding-left: 60px;
    }
  }

  div.icon {
    position: absolute;
    border-right: 1px solid #e9e9ea;
    width: 50px;
    left: 0;
    top: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 24px;
    }
  }
}
</style>