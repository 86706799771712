import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './core/components/ui/styles/styles.scss';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "@/@core/utils/focus-polyfill.js"

import LottieAnimation from "lottie-web-vue";
import uiPopup from "@/components/ui-popup.vue";
import {Core} from "@/@core/core";
import {screenMixin} from "@/@core/mixins/screen.mixin";
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import vue3Popper from "vue3-popper";

const app = createApp(App)
app.component('ui-popup', uiPopup);
const core = new Core();
core.loadCore();
app.config.globalProperties.$core = core;
app.mixin(screenMixin);
app.component('vue3-popper', vue3Popper);

app.use(VueTippy,
    {
        directive: 'tippy',
        component: 'tippy',
        componentSingleton: 'tippy-singleton',
        defaultProps: {
            placement: 'auto-end',
            allowHTML: true,
        },
    });
app.use(store).use(router).use(LottieAnimation).mount('#app')
