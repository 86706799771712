
import {Options, Vue} from 'vue-class-component';
import {mapState} from "vuex";
import {LottieAnimation} from "lottie-web-vue";
import UiButton from "@/components/ui-button.vue";

@Options({
  name: 'done-page',
  components: {UiButton, LottieAnimation},
  computed: {
    ...mapState(['steps'])
  }
})
export default class done extends Vue {

}
