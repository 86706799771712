
import {Options, Vue} from 'vue-class-component';
import {mapGetters, mapMutations, mapState} from "vuex";
import CartTheme from "@/layout/popup/info/logo/cart-theme.vue";

@Options({
  components: {CartTheme},
  computed: {
    ...mapState(['steps']),
    ...mapGetters(['getPrice', 'getPriceMonthly', 'getCurrentStep']),
  },
  data() {
    return {
      priceHasChanged: false,
      cartPopupState: false,
    }
  },
  watch: {
    getPrice() {
      if (this.getPrice > 0) {
        this.priceHasChanged = true;
      }
    }
  },
  methods: {
    ...mapMutations(['setCartOpened']),
    openPopup() {
      this.cartPopupState = true;
    },
    openSpecifics() {
      this.cartPopupState = false;
      this.setCartOpened(true);
      this.$emit('openSpecifics');
    },
    formatPrice(priceNum: number, showFloor = true) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      });

      let forReturn = formatter.format(priceNum).replace(/\s/g, "");
      if (!showFloor) {
        forReturn = forReturn.split(",")[0];
      }

      return forReturn;
    },
    getSelectedElementsCount() {
      let count = 0;
      for (const step of this.steps) {
        if (!step.cards) {
          continue;
        }

        for (const card of step.cards) {
          if (card.price && card.selected) {
            count++;
          }
        }

        if (step.childs) {
          for (const cStep of step.childs) {
            if (!cStep.cards) {
              continue;
            }

            for (const card of cStep.cards) {
              if (card.price && card.selected) {
                count++;
              }
            }
          }
        }
      }

      return count;
    }
  }
})
export default class FooterBar extends Vue {
}
