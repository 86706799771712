
import {Options, Vue} from 'vue-class-component';
import {LottieAnimation} from "lottie-web-vue";
import {mapMutations, mapState} from "vuex";

@Options({
  name: 'flow-pay',
  components: {LottieAnimation},
  computed: {
    ...mapState(['loading'])
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations(['setLoading'])
  },
  mounted() {
    this.setLoading(true);
    setTimeout(() => {
      //this.setLoading(false);
    }, 3000);
  }
})
export default class Pay extends Vue {

}
