
import {Options, Vue} from 'vue-class-component';
import {mapGetters, mapState} from "vuex";

@Options({
  name: 'ui-button-prev',
  computed: {
    ...mapGetters(['getCurrentStep']),
    ...mapState(['steps']),
  },
  data() {
    return {
      lastStep: 'pay-1'
    }
  },
})
export default class UiButtonPrev extends Vue {
}
