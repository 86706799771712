import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-410a1282"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ui-button-prev"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_ctx.getCurrentStep.getName() !== _ctx.lastStep)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_button, {
          style: {"width":"160px"},
          variety: "skip",
          iconleft: require('@/assets/imgs/pages/pfeil-links.svg')
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Zurück ")
          ]),
          _: 1
        }, 8, ["iconleft"])
      ]))
    : _createCommentVNode("", true)
}