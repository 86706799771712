
import {Options, Vue} from 'vue-class-component';
import {mapGetters, mapMutations, mapState} from "vuex";
import FlowCard from "@/view/flow/card/card.vue";
import {Method} from "@/@core/ajax/request/_schema/method.enum";
import {RequestParameter} from "@/@core/ajax/request/request-parameter";
import UiInput_button from "@/components/ui-input_button.vue";
import UiInput from "@/components/ui-input.vue";
import UiInput2 from "@/components/ui-input2.vue";
import UiButton from "@/components/ui-button.vue";
import UiCheckbox from "@/components/ui-checkbox.vue";
import {Step} from "@/core/entity/step/step.entity";
import {
  CartItem,
  PaymentType,
  RequestCart,
  RequestInterface,
  RequestPayment, Type
} from "@/core/entity/interface/request.interface";
import UiForm from "@/components/ui-form.vue";
import {FormParameter} from "@/components/form-parameter";

@Options({
  name: 'flow-cards',
  components: {UiForm, UiCheckbox, UiButton, UiInput2, UiInput, UiInput_button, FlowCard},
  computed: {
    ...mapGetters(['getCurrentStep']),
    ...mapState('specifics', ['specifics']),
    ...mapState(['visited', 'currentStep', 'steps', 'visited2', 'specific', 'name', 'av']),
  },
  data() {
    return {
      domain: '',
      error: '',
      lastname: '',
      loading: false,
      stat: null,
      test: '',
      street: '',
      postcode: '',
      city: '',
      phone: '',
      country: 'de',
      mailAddress: '',
      companyName: '',
      formParameter: null,
      shake: false,
      alreadyUpdated: false
    }
  },
  watch: {
    getCurrentStep() {
      console.log("captcha toggle")
      console.log(this.getCurrentStep.getType())
      if (this.getCurrentStep.getType() === 'pay') {
        document.body.classList.add('captcha');
      } else {
        document.body.classList.remove('captcha');
      }
    }
  },
  updated() {
    if (this.getCurrentStep.getType() === 'pay' && !this.alreadyUpdated) {
      this.initializeFormParameter();
      this.alreadyUpdated = true;
    }
  },
  methods: {
    ...mapMutations(['next', 'previous', 'setName', 'setSpecific', 'setSpecCard']),
    shakeMe() {
      this.shake = true;
      setTimeout((() => {
        this.shake = false;
      }), 1000);
    },
    downloadAGB(){
      fetch("/AGB's McBrand's.pdf")
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "AGB's McBrand's.pdf";
            link.click();
            window.URL.revokeObjectURL(link.href);
          })
          .catch(error => console.error('Error downloading the file:', error));
    },
    initializeFormParameter() {
      this.formParameter = {
        parameter: [
          {
            name: 'firstname',
            error: 'Vorname muss angegeben sein',
            ref: this.$refs.firstname,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'lastname',
            error: 'Nachname muss angegeben sein',
            ref: this.$refs.lastname,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'company',
            error: 'Firmenname muss angegeben sein',
            ref: this.$refs.company,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'street',
            error: 'Straße muss angegeben sein',
            ref: this.$refs.street,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'postcode',
            error: 'Postleitzahl muss angegeben sein',
            ref: this.$refs.postcode,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'city',
            error: 'Stadt muss angegeben sein',
            ref: this.$refs.city,
            pipe: {
              min: 1,
              max: 255,
            },
          },
          {
            name: 'mail',
            ref: this.$refs.mail,
            pipe: {
              isMail: true,
            },
          },
          {
            name: 'phone',
            error: 'Telefonnummer muss angegeben sein',
            ref: this.$refs.phone,
            pipe: {
              isPhoneNumber: true
            },
          },
        ],
        submit: [
          {
            name: 'save',
            ref: this.$refs.save,
          },
        ],
      } as FormParameter;
    },
    getMobileCardClass() {
      if (this.getCurrentStep.getCards().length < 2) {
        return ['d-flex', 'justify-coswntent-center', 'col-12']
      }
    },
    prediction(pred: any, type = 'street') {
      const match = this.street.match(/\b\d+\w*\b/);
      this.street = `${pred.road} ${match && match.length > 0 ? match[0] : ''}`;
      this.postcode = pred.postcode;
      this.city = pred.town;

      if (type === 'street') {
        document.getElementById('street')?.click();
      }

      const languages = [
        {
          icon: require('@/assets/imgs/languages/germany.png'),
          code: 'de',
          phone: '+49',
        },
        {
          icon: require('@/assets/imgs/languages/austria.png'),
          code: 'au',
          phone: '+43'
        },
        {
          icon: require('@/assets/imgs/languages/switzerland.png'),
          code: 'sw',
          phone: '+41'
        }
      ];


      const phone = this.$refs.phone;
      const languageMapping: any = {
        'at': languages[1],
        'de': languages[0],
        'ch': languages[2]
      };

      const language = languageMapping[pred.country_code];

      if (language) {
        this.country = language;
        phone.selectLanguage(language);
      }
    },
    async yuhu() {

      const json = await this.$core.ajax?.fetch({
        url: 'pages/flow/request',
        method: Method.POST,
        json: {
          body: this.buildRequestObject(),
        },
        auth: false,
      });

      if (json.success) {
        this.$router.push('/done')
      }
    },
    buildRequestObject() {
      const cartItems: CartItem[] = [];

      for (const step of this.steps) {

        let cartItem: any = {};

        switch (step.name) {
          case 'logo':
            cartItem['type'] = Type.LOGO;
            break;
          case 'print':
            cartItem['type'] = Type.PRINT;
            break;
          case 'advertisement':
            cartItem['type'] = Type.ADVERTISE;
            break;
          case 'website':
            cartItem['type'] = Type.WEBSITE;
            break;
          case 'socialmedia':
            cartItem['type'] = Type.SOCIALMEDIA;
            break;
          default:
            continue;
        }


        if (!step.cards) {
          continue;
        }

        for (const card of step.cards) {
          if (card.price && card.selected) {
            cartItem['specifics'] = card;
          }
        }

        if (step.childs) {
          for (const cStep of step.childs) {
            if (!cStep.cards) {
              continue;
            }

            let children = [];
            for (const card of cStep.cards) {
              if (card.price && card.selected) {
                children.push(card);
              }
            }

            cartItem['children'] = children;
          }
        }

        cartItems.push(cartItem);
      }

      const payment: RequestPayment = {
        type: PaymentType.ONETIME,
      }

      const cart: RequestCart = {
        items: cartItems,
        payment
      }

      const request: RequestInterface = {
        cart,
        city: this.city,
        companyName: this.companyName,
        mailAddress: this.mailAddress,
        firstname: this.name,
        lastname: this.lastname,
        phone: this.phone,
        postcode: this.postcode,
        street: this.street
      }

      return request;
    },
    domainInputChanged() {
      this.setAV(null)
    },
    clickInfo(card: any) {
      this.$emit('clickInfo', card)
    },
    getSpecificByCardName(cardName: string) {
      for (const spec of this.specifics) {
        if (spec.name === cardName) {
          return spec;
        }
      }
      return null;
    },
    clickCard(myCard: any, edit = false) {
      if (myCard.selected && myCard.selected === true) {
        myCard.selected = false;
        if (myCard.specs) {
          myCard.specs = {
            edited: false
          }
        }

        return;
      }

      if (myCard.next || this.getCurrentStep.getType() === 'single-select') {
        for (const card of this.getCurrentStep.getCards()) {
          card.selected = false;
          if (card.specs) {
            card.specs = {
              edited: false
            }
          }
        }

        if (this.getCurrentStep.getType() === 'single-select') {
          myCard.selected = !myCard.selected;
        }
      }

      if (!myCard.next) {
        for (const card of this.getCurrentStep.getCards()) {
          if (card.next) {
            card.selected = !card.selected;
          }
        }
      }

      const specifics = this.getSpecificByCardName(myCard.name);
      if (specifics) {
        if (myCard.specs.edited) {
          if (!edit) {
            myCard.specs = {
              edited: false
            };
            myCard.selected = false;
            return;
          }

          this.setSpecCard(myCard);
          specifics['specifics']['attr'] = myCard.specs.attr;
          this.setSpecific(JSON.parse(JSON.stringify(specifics)));
          this.$emit('specific');
          return;

        }
        this.setSpecCard(myCard);
        this.setSpecific(JSON.parse(JSON.stringify(specifics)));
        this.$emit('specific');
        return;
      }


      if (this.getCurrentStep.getType() !== 'single-select') {
        myCard.selected = !myCard.selected;
      }

      const step: Step = this.getCurrentStep;
      if (step.getRaw()['noSkip'] && step.getRaw()['noSkip'] === true) {
        this.next();
      }
    },
    async skipDomain() {
      this.next();
    },
    ...mapMutations(['setAV']),
    async checkDomain() {
      if (this.av) {
        const element = this.$refs.scroll;
        this.next();
        return;
      }

      if (this.domain.trim().length === 0) {
        return;
      }

      const regex = /^[a-zA-Z0-9-]+\.([a-zA-Z0-9-]+\.)*((de))$/;
      if (!regex.test(this.domain)) {
        this.domain += '.de';
      }

      this.loading = true;
      const json = await this.$core.ajax?.fetch({
        url: 'pages/flow/steps/domain/checkAvailable',
        method: Method.GET,
        json: {
          domain: this.domain
        },
        auth: false,
      } as RequestParameter);

      this.setAV(json['available'])
      this.stat = json['stat'];

      this.loading = false;
    },
  }
})
export default class FlowCards extends Vue {
}
